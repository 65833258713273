import axios from 'axios';
import { I18n } from 'react-redux-i18n';

import { UNRECOGNIZED_ERROR } from '../../../../../../lib/constants';
import { getCurrentSelectedObid } from '../../../../../../lib/getObid';
import * as MessageActions from '../../../../../store/actions';

export const GET_CHANNELS = '[CHANNELS] GET CHANNELS';
export const GET_CONNECTED_USERS = '[CHANNELS] GET CONNECTED USERS';
export const SET_SEARCH_TEXT = '[CHANNELS] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_CONTACTS = '[CHANNELS] TOGGLE IN SELECTED CONTACTS';
export const SELECT_ALL_CHANNELS = '[CHANNELS] SELECT ALL CONTACTS';
export const DESELECT_ALL_CHANNELS = '[CHANNELS] DESELECT ALL CONTACTS';
export const OPEN_NEW_CHANNEL_DIALOG = '[CHANNELS] OPEN NEW CHANNEL DIALOG';
export const CLOSE_NEW_CHANNEL_DIALOG = '[CHANNELS] CLOSE NEW CHANNEL DIALOG';
export const OPEN_EDIT_CHANNEL_DIALOG = '[CHANNELS] OPEN EDIT CHANNEL DIALOG';
export const CLOSE_EDIT_CHANNEL_DIALOG = '[CHANNELS] CLOSE EDIT CONTACT DIALOG';
export const ADD_CHANNEL = '[CHANNELS] ADD CHANNEL';
export const UPDATE_CHANNEL = '[CHANNELS] UPDATE CHANNEL';
export const REMOVE_CHANNEL = '[CHANNELS] REMOVE CHANNEL';
export const GET_SELECTED_CHANNEL_INFO = '[CHANNELS] GET SELECTED CHANNEL INFO';
export const OPEN_NEW_CONNECTED_USER_DIALOG = '[CHANNELS] OPEN NEW CONNECTED USER DIALOG';
export const CLOSE_NEW_CONNECTED_USER_DIALOG = '[CHANNELS] CLOSE NEW CONNECTED USER DIALOG';
export const OPEN_EDIT_CONNECTED_USER_DIALOG = '[CHANNELS] OPEN EDIT CONNECTED USER DIALOG';
export const CLOSE_EDIT_CONNECTED_USER_DIALOG = '[CHANNELS] CLOSE EDIT CONNECTED USER DIALOG';
export const REMOVE_CHANNELS = '[CHANNELS] REMOVE CHANNELS';
export const SET_LOADING_TRUE = '[CHANNELS] SET LOADING TRUE';
export const TOGGLE_HIDDEN = '[CHANNELS] TOGGLE HIDDEN';
export const SET_LOADING_CHANNELS_DIALOG = '[CHANNELS] SET LOADING CONTACT DIALOG';
export const SET_LOADING_FALSE = '[CHANNELS] SET LOADING FALSE';

export function setLoadingChannelsDialog() {
  return {
    type: SET_LOADING_CHANNELS_DIALOG
  };
}

export function getChannels(loading = true) {
  const request = axios.get('/api/channels/list', {
    params: {
      selectedObid: getCurrentSelectedObid()
    }
  });

  return dispatch => {
    if (loading) dispatch(setLoadingTrue());
    request
      .then(response =>
        dispatch({
          type: GET_CHANNELS,
          payload: response.data
        })
      )
      .finally(() => {
        dispatch(setLoadingFalse());
      });
  };
}

export function setLoadingTrue() {
  return {
    type: SET_LOADING_TRUE
  };
}

export function setLoadingFalse() {
  return {
    type: SET_LOADING_FALSE
  };
}

export function setSearchText(event) {
  return {
    type: SET_SEARCH_TEXT,
    searchText: event.target.value
  };
}

export function toggleInSelectedContacts(contactId) {
  return {
    type: TOGGLE_IN_SELECTED_CONTACTS,
    contactId
  };
}

export function selectAllChannels() {
  return {
    type: SELECT_ALL_CHANNELS
  };
}

export function deSelectAllChannels() {
  return {
    type: DESELECT_ALL_CHANNELS
  };
}

export function openNewChannelDialog() {
  return {
    type: OPEN_NEW_CHANNEL_DIALOG
  };
}

export function closeNewChannelDialog() {
  return {
    type: CLOSE_NEW_CHANNEL_DIALOG
  };
}

export function openNewConnectedUserDialog() {
  return {
    type: OPEN_NEW_CONNECTED_USER_DIALOG
  };
}

export function closeNewConnectedUserDialog() {
  return {
    type: CLOSE_NEW_CONNECTED_USER_DIALOG
  };
}

export function closeEditChannelDialog() {
  return {
    type: CLOSE_EDIT_CHANNEL_DIALOG
  };
}

export function closeEditConnectedUserDialog() {
  return {
    type: CLOSE_EDIT_CONNECTED_USER_DIALOG
  };
}

export function openEditChannelDialog(data) {
  return {
    type: OPEN_EDIT_CHANNEL_DIALOG,
    data
  };
}

export function addChannel(newChannel) {
  return dispatch => {
    newChannel['autoAddJobTitles'] = newChannel.jobTitles.map(x => x.id);

    axios
      .post('/api/channels/add', {
        ...newChannel,
        selectedObid: getCurrentSelectedObid()
      })
      .then(response => {
        if (response.data.msg) {
          dispatch(
            MessageActions.showMessage({
              message: `${I18n.t('Channel Already Exists')}`
            })
          );
        } else {
          dispatch({
            type: ADD_CHANNEL,
            payload: {
              ...newChannel,
              id: response.data.groupId,
              usersPerJobTitle: response.data.jobtitlesToUserN,
              subscribers: {
                jobTitleId: newChannel.jobTitles.map(x => x.id),
                uId: []
              }
            }
          });
        }
      })
      .catch(err => {
        if (err.response && err.response.status === 409)
          dispatch(
            MessageActions.showMessage({
              message: `${I18n.t('Channel Already Exists')}`
            })
          );
        else
          dispatch(
            MessageActions.showMessage({
              message: `${I18n.t(UNRECOGNIZED_ERROR)}`
            })
          );
      });
  };
}

export function addSubscriber(newChannel, channelId) {
  return dispatch => {
    newChannel['channelId'] = channelId;
    const request = axios.post('/api/channels/subscribeUsersToChannel', {
      selectedObid: getCurrentSelectedObid(),
      uids: [newChannel.usersForSelect],
      channelId: channelId
    });

    return request
      .then(() =>
        Promise.all([
          dispatch({
            type: ADD_CHANNEL,
            payload: {}
          })
        ])
      )
      .then(() => dispatch(getConnectedUsers(channelId)));
  };
}

export function updateSubscriber(channel, language) {
  return dispatch => {
    channel['languageId'] = language.id;
    channel['languageTranslationName'] = language.translationName;
    const request = axios.post('/api/channels/editSubscriber', {
      selectedObid: getCurrentSelectedObid(),
      channel
    });

    return request.then(() =>
      Promise.all([
        dispatch({
          type: UPDATE_CHANNEL
        })
      ]).then(() => dispatch(getChannels(false)))
    );
  };
}

export function updateChannel(channel) {
  return dispatch => {
    channel['defaultSubscribers'] = {
      jobTitleId: channel.jobTitles.map(x => x.id),
      uId: []
    };
    channel['subscribers'] = {
      jobTitleId: channel.jobTitles.map(x => x.id),
      uId: []
    };

    axios
      .post('/api/channels/edit', {
        ...channel,
        selectedObid: getCurrentSelectedObid()
      })
      .then(response => {
        dispatch({
          type: UPDATE_CHANNEL,
          usersPerJobTitle: response.data,
          channel
        });
      })
      .catch(err => {
        if (err.response && err.response.status === 409)
          dispatch(
            MessageActions.showMessage({
              message: `${I18n.t('Channel Already Exists')}`
            })
          );
        else
          dispatch(
            MessageActions.showMessage({
              message: `${I18n.t(UNRECOGNIZED_ERROR)}`
            })
          );
      });
  };
}

export function removeContact(channelId) {
  return dispatch => {
    const request = axios.post('/api/channels/delete', {
      selectedObid: getCurrentSelectedObid(),
      channels: [channelId]
    });

    return request.then(() =>
      Promise.all([
        dispatch({
          type: REMOVE_CHANNEL,
          channelId
        })
      ])
    );
  };
}

export function removeChannels(channelIds) {
  return dispatch => {
    const request = axios.post('/api/channels/delete', {
      selectedObid: getCurrentSelectedObid(),
      channels: channelIds
    });

    return request.then(() =>
      Promise.all([
        dispatch({
          type: REMOVE_CHANNELS,
          channelIds
        })
      ])
    );
  };
}

export function getSelectedChannelInfo(id) {
  const request = axios.get('/api/channels/get', {
    params: {
      selectedObid: getCurrentSelectedObid(),
      channelId: id
    }
  });

  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_SELECTED_CHANNEL_INFO,
        payload: response.data
      })
    );
}

export function getConnectedUsers(channelId) {
  const request = axios.get('/api/channels/subscribedUsers', {
    params: {
      selectedObid: getCurrentSelectedObid(),
      channelId: channelId
    }
  });

  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_CONNECTED_USERS,
        payload: response.data
      })
    );
}

export function removeSubscriber(subscriberId, channelId) {
  return dispatch => {
    const request = axios.post('/api/channels/unsubscribeUsersFromChannel', {
      selectedObid: getCurrentSelectedObid(),
      uids: [subscriberId],
      channelId
    });

    return request.then(() =>
      Promise.all([
        dispatch({
          type: DESELECT_ALL_CHANNELS
        })
      ]).then(() => dispatch(getConnectedUsers(channelId)))
    );
  };
}

export function removeSubscribers(subscriberIds, channelId) {
  return dispatch => {
    const request = axios.post('/api/channels/unsubscribeUsersFromChannel', {
      selectedObid: getCurrentSelectedObid(),
      uids: subscriberIds,
      channelId
    });

    return request.then(() =>
      Promise.all([
        dispatch({
          type: DESELECT_ALL_CHANNELS
        })
      ]).then(() => dispatch(getConnectedUsers(channelId)))
    );
  };
}
