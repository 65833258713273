import axios from 'axios';

import { getCurrentSelectedObid } from '../../../../../../../lib/getObid';

export const GET_PREDEFINED_MESSAGES = '[PREDEFINED MESSAGES] GET PREDEFINED MESSAGES';
export const SET_SEARCH_TEXT = '[PREDEFINED MESSAGES] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_PREDEFINED_MESSAGES = '[PREDEFINED MESSAGES] TOGGLE IN PREDEFINED MESSAGES';
export const SELECT_ALL_PREDEFINED_MESSAGES = '[PREDEFINED MESSAGES] SELECT ALL PREDEFINED MESSAGES';
export const DESELECT_ALL_PREDEFINED_MESSAGES = '[PREDEFINED MESSAGES] DESELECT ALL PREDEFINED MESSAGES';
export const OPEN_NEW_PREDEFINED_MESSAGE_DIALOG = '[PREDEFINED MESSAGES] OPEN NEW PREDEFINED MESSAGE DIALOG';
export const OPEN_EDIT_PREDEFINED_MESSAGE_DIALOG = '[PREDEFINED MESSAGES] OPEN EDIT PREDEFINED MESSAGE DIALOG';
export const CLOSE_PREDEFINED_MESSAGE_DIALOG = '[PREDEFINED MESSAGES] CLOSE PREDEFINED MESSAGE DIALOG';
export const ADD_PREDEFINED_MESSAGE = '[PREDEFINED MESSAGES] ADD PREDEFINED MESSAGE';
export const UPDATE_PREDEFINED_MESSAGE = '[PREDEFINED MESSAGES] UPDATE PREDEFINED MESSAGE';
export const REMOVE_PREDEFINED_MESSAGE = '[PREDEFINED MESSAGES] REMOVE PREDEFINED MESSAGE';
export const TOGGLE_DISABLED = '[PREDEFINED MESSAGES] TOGGLE DISABLED';
export const REMOVE_MESSAGES = '[PREDEFINED MESSAGES] REMOVE MESSAGES';
export const SET_LOADING_TRUE = '[PREDEFINED MESSAGES] SET LOADING TRUE';
export const REMOVE_JOB_TITLE = '[PREDEFINED MESSAGES] REMOVE JOB TITLE';
export const ADD_JOB_TITLE = '[PREDEFINED MESSAGES] ADD JOB TITLE';
export const DISABLE_MULTIPLE = '[PREDEFINED MESSAGES] DISABLE MULTIPLE';
export const ENABLE_MULTIPLE = '[PREDEFINED MESSAGES] ENABLE MULTIPLE';

export function getPredefinedMessages(loading = true) {
  const request = axios.get('/api/predefinedMsgs/list', {
    params: {
      selectedObid: getCurrentSelectedObid()
    }
  });

  return dispatch => {
    if (loading) dispatch(setLoadingTrue());
    request.then(response => {
      return dispatch({
        type: GET_PREDEFINED_MESSAGES,
        payload: response.data
      });
    });
  };
}

export function removeJobTitle(jobTitleId, predefinedMessageId) {
  return dispatch => {
    const request = axios.post('/api/predefinedMsgs/removeJobTitle', {
      selectedObid: getCurrentSelectedObid(),
      jobTitleId: [jobTitleId],
      predefinedMessageId: parseInt(predefinedMessageId)
    });

    return request.then(() =>
      Promise.all([
        dispatch({
          type: REMOVE_JOB_TITLE,
          jobTitleId,
          predefinedMessageId
        })
      ])
    );
  };
}

export function addJobTitle(jobTitleId, predefinedMessageId) {
  return dispatch => {
    const request = axios.post('/api/predefinedMsgs/addJobTitle', {
      selectedObid: getCurrentSelectedObid(),
      jobTitleId: jobTitleId,
      predefinedMessageId: parseInt(predefinedMessageId)
    });

    return request.then(() =>
      Promise.all([
        dispatch({
          type: ADD_JOB_TITLE,
          jobTitleId,
          predefinedMessageId
        })
      ])
    );
  };
}

export function setLoadingTrue() {
  return {
    type: SET_LOADING_TRUE
  };
}

export function setSearchText(event) {
  return {
    type: SET_SEARCH_TEXT,
    searchText: event.target.value
  };
}

export function toggleInSelectedPredefinedMessages(contactId) {
  return {
    type: TOGGLE_IN_SELECTED_PREDEFINED_MESSAGES,
    contactId
  };
}

export function selectAllPredefinedMessages() {
  return {
    type: SELECT_ALL_PREDEFINED_MESSAGES
  };
}

export function deSelectAllPredefinedMessages() {
  return {
    type: DESELECT_ALL_PREDEFINED_MESSAGES
  };
}

export function openNewPredefinedMessageDialog() {
  return {
    type: OPEN_NEW_PREDEFINED_MESSAGE_DIALOG
  };
}

export function closePredefinedMessageDialog() {
  return {
    type: CLOSE_PREDEFINED_MESSAGE_DIALOG
  };
}

export function openEditPredefinedMessageDialog(data) {
  return {
    type: OPEN_EDIT_PREDEFINED_MESSAGE_DIALOG,
    data
  };
}

export function addPredefinedMessage(newSms) {
  return dispatch => {
    const request = axios.post('/api/predefinedMsgs/add', {
      selectedObid: getCurrentSelectedObid(),
      ...newSms
    });

    return request.then(() =>
      Promise.all([
        dispatch({
          type: ADD_PREDEFINED_MESSAGE
        })
      ]).then(() => dispatch(getPredefinedMessages(false)))
    );
  };
}

export function updatePredefinedMessage(sms) {
  return dispatch => {
    sms['id'] = parseInt(sms['id']);
    const request = axios.post('/api/predefinedMsgs/edit', {
      selectedObid: getCurrentSelectedObid(),
      ...sms
    });

    return request.then(() =>
      Promise.all([
        dispatch({
          type: UPDATE_PREDEFINED_MESSAGE,
          sms
        })
      ])
    );
  };
}

export function removePredefinedMessage(smsId) {
  return dispatch => {
    axios.post('/api/predefinedMsgs/delete', {
      selectedObid: getCurrentSelectedObid(),
      messagesToDelete: [smsId]
    });

    dispatch({
      type: REMOVE_PREDEFINED_MESSAGE,
      smsId: smsId
    });
  };
}

export function removeMessages(messagesids) {
  return dispatch => {
    axios.post('/api/predefinedMsgs/delete', {
      selectedObid: getCurrentSelectedObid(),
      messagesToDelete: messagesids
    });

    dispatch({
      type: REMOVE_MESSAGES,
      messagesids
    });
  };
}

export function enableMultiple(smsIds) {
  return dispatch => {
    let arr = smsIds.map(x => {
      return { id: x, state: 1 };
    });
    axios.post('/api/predefinedMsgs/toggleDisable', {
      obid: getCurrentSelectedObid(),
      table: [...arr]
    });

    dispatch({
      type: ENABLE_MULTIPLE,
      smsIds
    });
    dispatch({
      type: DESELECT_ALL_PREDEFINED_MESSAGES
    });
  };
}

export function disableMultiple(smsIds) {
  return dispatch => {
    let arr = smsIds.map(x => {
      return { id: x, state: 0 };
    });
    axios.post('/api/predefinedMsgs/toggleDisable', {
      obid: getCurrentSelectedObid(),
      table: [...arr]
    });

    dispatch({
      type: DISABLE_MULTIPLE,
      smsIds
    });
    dispatch({
      type: DESELECT_ALL_PREDEFINED_MESSAGES
    });
  };
}

export function toggleDisabled(smsId, value) {
  return dispatch => {
    axios.post('/api/predefinedMsgs/toggleDisable', {
      obid: getCurrentSelectedObid(),
      table: [{ id: smsId, state: value ? 1 : 0 }]
    });

    dispatch({
      type: TOGGLE_DISABLED,
      smsId,
      state: value ? 1 : 0
    });
  };
}
