// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GenericObject = Record<string, any>;

export enum FacilityType {
  STORE = 'store',
  HOTEL = 'hotel',
  OTHER = 'other'
}

export interface Supervisor {
  email: string | null;
  id: number;
  name: string;
  phone: string | null;
  surname: string;
}

export type Obid = string | number;

export type SimCards = string[];

export type Plmn = string;

export enum MultidashboardPath {
  DEV = 'dev',
  DEV8 = 'dev8',
  HOTELS_DEV = 'hotel-dev',
  TEST = 't',
  ALPHA = 'a',
  BETA = 'b',
  CLOUD = 'a'
}

export enum Instance {
  INSTANCE_TYPE_DEV = 'Dev',
  INSTANCE_TYPE_DEV8 = 'Dev8',
  INSTANCE_TYPE_HOTELS_DEV = 'HotelsDev',
  INSTANCE_TYPE_TEST = 'Test',
  INSTANCE_TYPE_HOTELS = 'Hotels',
  INSTANCE_TYPE_ALPHA = 'Alpha',
  INSTANCE_TYPE_BETA = 'Beta',
  INSTANCE_TYPE_CLOUD = 'Production'
}

export enum FetchingState {
  INITIAL = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed'
}

export type NestedKeyOf<ObjectType> = {
  [Key in keyof ObjectType & string]: ObjectType[Key] extends GenericObject
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & string];

export type SetNestedValue<T> = (name: NestedKeyOf<T>, value: unknown) => void;

export type BooleanProperties<T> = {
  [K in keyof T]: boolean;
};

export type FacilityStatus = string;

export interface ObjectDetails {
  addressName: string;
  city: string;
  country: string;
  demoObjectId: number | null;
  externalId: number | null;
  facilityType: FacilityType;
  googlePlaceId: string | null;
  group: string;
  id: string;
  infrastructure: string;
  lang: string;
  latitude: number;
  longitude: number;
  object_name: string;
  phone: string;
  processId: number;
  status: FacilityStatus;
  street: string;
  streetNumber: string;
  supervisor: Supervisor | null;
  supervisorId: number;
  zipCode: string;
}

export enum UserRole {
  ROLE_OWNER = 'owner',
  ROLE_ADMIN = 'admin',
  ROLE_VIEW_ONLY = 'viewOnly',
  ROLE_SERVICE = 'service'
}

export interface FacilityObjectType {
  activeForUser: boolean;
  facilityType: FacilityType;
  id: string;
  multidashboardPath: MultidashboardPath;
  name: string;
  status: FacilityStatus;
}

export interface Supervisor {
  email: string | null;
  id: number;
  name: string;
  phone: string | null;
  surname: string;
}

export interface PredefinedMessage {
  id: number | null;
  text: string;
}

export enum PredefinedMessageDialogType {
  NEW = 'new',
  EDIT = 'edit'
}

export enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete'
}

export enum DeviceType {
  WATCH = 'watch',
  WEBCHAT = 'webchat',
  TABLET = 'tablet',
  BUTTON = 'button',
  RVM = 'reverse_vending_machine'
}
