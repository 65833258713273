import { TextField, TextFieldProps } from '@material-ui/core';
import { useTypedSelector } from 'app/store/reducers/hooks/useTypedSelector';
import React, { useMemo } from 'react';
import { Translate } from 'react-redux-i18n';

import TranslationTooltip from '../tooltips/TranslationTooltip';

interface EnhancedTextFieldProps extends Omit<TextFieldProps, 'label'> {
  label: string;
}

function EnhancedTextField({ label, ...props }: EnhancedTextFieldProps) {
  const translatorModeEnabled = useTypedSelector(({ common }) => common.common.translatorModeEnabled);

  const translatedLabel = useMemo(() => (label ? <Translate value={label} /> : null), [label]);

  if (translatorModeEnabled)
    return (
      <TranslationTooltip title={label}>
        <TextField {...props} label={translatedLabel} variant={props.variant || 'outlined'} />
      </TranslationTooltip>
    );
  else return <TextField {...props} label={translatedLabel} variant={props.variant || 'outlined'} />;
}

export default EnhancedTextField;
