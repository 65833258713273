import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { CURRENT_PASSWORD_INCORRECT } from '../../../lib/constants';
import { RESET_STORE } from '../../store/actions/reduxActions';
import { SET_USER_DATA } from './actions';

export const initialState = {
  editUserDialog: {
    open: false,
    currentPasswordIncorrect: false
  }
};

export const submitUserDetails = user => async dispatch => {
  axios
    .post(`/api/dashboard-users/details`, user)
    .then(() => {
      dispatch({
        type: SET_USER_DATA,
        payload: user
      });
      dispatch(setUserDetailsDialogOpen(false));
      dispatch(setCurrentPasswordIncorrect(false));
    })
    .catch(error => {
      if (error?.response?.data?.error === CURRENT_PASSWORD_INCORRECT) {
        dispatch(setCurrentPasswordIncorrect(true));
      }
    });
};

const devicesSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetailsDialogOpen: (state, action) => {
      state.editUserDialog.open = action.payload;
    },
    setCurrentPasswordIncorrect: (state, action) => {
      state.editUserDialog.currentPasswordIncorrect = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(RESET_STORE, () => {
      return { ...initialState };
    });
  }
});
export const { setUserDetailsDialogOpen, setCurrentPasswordIncorrect } = devicesSlice.actions;
export default devicesSlice.reducer;
