/* eslint-disable complexity */
import { Manager } from 'app/main/apps/partner/store/PartnerSlice';
import { AnyAction } from 'redux';

import { RESET_STORE } from '../../../../../store/actions/reduxActions';
import * as Actions from '../actions';

type Managers = {
  [key: number]: Manager;
};

interface InitialState {
  entities: Managers;
  searchText: string;
  selectedEntitiesIds: number[];
  loading: boolean;
  invitationsLoading: boolean;
  entityDialog: {
    type: string;
    props: {
      open: boolean;
    };
  };
}

const initialState: InitialState = {
  entities: [],
  searchText: '',
  selectedEntitiesIds: [],
  loading: true,
  invitationsLoading: true,
  entityDialog: {
    type: 'new',
    props: {
      open: false
    }
  }
};

const accessReducer = function (state = initialState, action: AnyAction) {
  switch (action.type) {
    case Actions.GET_USERS: {
      return {
        ...state,
        entities: action.payload,
        loading: false
      };
    }
    case Actions.SET_LOADING_TRUE: {
      return {
        ...state,
        loading: true
      };
    }
    case Actions.SET_LOADING_FALSE: {
      return {
        ...state,
        loading: false
      };
    }
    case Actions.SET_MANAGERS_INVITATIONS_LOADING_TRUE: {
      return {
        ...state,
        invitationsLoading: true
      };
    }
    case Actions.SET_MANAGERS_INVITATIONS_LOADING_FALSE: {
      return {
        ...state,
        invitationsLoading: false
      };
    }
    case Actions.SET_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.searchText
      };
    }
    case Actions.TOGGLE_IN_SELECTED_ENTITIES: {
      const entityId = action.entityId;

      let selectedEntitiesIds = [...state.selectedEntitiesIds];

      if (selectedEntitiesIds.find(id => id === entityId) !== undefined) {
        selectedEntitiesIds = selectedEntitiesIds.filter(id => id !== entityId);
      } else {
        selectedEntitiesIds = [...selectedEntitiesIds, entityId];
      }

      return {
        ...state,
        selectedEntitiesIds: selectedEntitiesIds
      };
    }
    case Actions.SELECT_ALL_ENTITIES: {
      const selectedManagersIds = Object.values(state.entities)
        .filter(manager => !manager.is_from_group)
        .map(manager => manager.id);

      return {
        ...state,
        selectedEntitiesIds: selectedManagersIds
      };
    }
    case Actions.DESELECT_ALL_ENTITIES: {
      return {
        ...state,
        selectedEntitiesIds: []
      };
    }
    case Actions.OPEN_NEW_ENTITY_DIALOG: {
      return {
        ...state,
        entityDialog: {
          type: 'new',
          props: {
            open: true
          }
        }
      };
    }
    case Actions.CLOSE_NEW_ENTITY_DIALOG: {
      return {
        ...state,
        entityDialog: {
          type: 'new',
          props: {
            open: false
          }
        }
      };
    }
    case Actions.ADD_MANAGER: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.newManager.id]: {
            ...action.newManager
          }
        }
      };
    }
    case RESET_STORE:
      return initialState;
    default: {
      return state;
    }
  }
};

export default accessReducer;
