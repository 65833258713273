import { CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { useHistory } from 'react-router';

import fullCwatchLogo from '../../../assets/fullCwatchLogo.jpg';
import { fetchIsAccountCompleted, setSelectedView } from '../../auth/store/LoginSlice';
import EnhancedTranslate from '../../common-components/EnhancedTranslate';
import { showMessage } from '../../store/actions';
import GoBackToLoginButton from '../GoBackToLoginButton';
import LoginFooter from '../LoginFooter';
import { VIEW_COMPLETE_REGISTRATION_NEW_USER, VIEW_LOGIN } from '../constants';

export default function InvitationView() {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    invitationLinkDetailsError,
    invitationLinkDetailsLoading,
    token,
    isAccountCompleted,
    isAccountCompletedLoading
  } = useSelector(state => state.login);

  useEffect(() => {
    dispatch(fetchIsAccountCompleted(token));
  }, []);

  useEffect(() => {
    if (isAccountCompleted !== null) {
      if (isAccountCompleted) {
        history.push('/login');
        dispatch(setSelectedView(VIEW_LOGIN));

        dispatch(
          showMessage({
            message: I18n.t('Account already created'),
            variant: 'success'
          })
        );
      } else {
        dispatch(setSelectedView(VIEW_COMPLETE_REGISTRATION_NEW_USER));
      }
    }
  }, [dispatch, isAccountCompleted, token]);

  function isLoading() {
    return invitationLinkDetailsLoading || isAccountCompletedLoading;
  }

  return (
    <div className='flex h-full flex-col p-32'>
      <GoBackToLoginButton />
      <img className='m-32 w-256' src={fullCwatchLogo} alt='logo' />

      {isLoading() ? (
        <div className='mt-32 flex justify-center'>
          <CircularProgress color='secondary' />
        </div>
      ) : (
        <>
          {invitationLinkDetailsError !== null && (
            <Alert severity='error'>
              <EnhancedTranslate value={invitationLinkDetailsError} />
            </Alert>
          )}
        </>
      )}
      <div className='mt-8 flex-1' />
      <LoginFooter />
    </div>
  );
}
