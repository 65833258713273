import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AssignedFacilityType } from './types';

interface InitialState {
  assignedFacilities: AssignedFacilityType[];
  isMultiSelect: boolean;
  isAssignToGroupDialogOpen: boolean;
}

const initialState: InitialState = {
  assignedFacilities: [],
  isMultiSelect: false,
  isAssignToGroupDialogOpen: false
};

export const assignToGroupSlice = createSlice({
  name: 'assignToGroupDialog',
  initialState,
  reducers: {
    openAssignToGroupDialog: (state, action: PayloadAction<AssignedFacilityType | undefined>) => {
      state.isAssignToGroupDialogOpen = true;
      state.isMultiSelect = !action.payload;

      if (action.payload) {
        state.assignedFacilities = [action.payload];
      }
    },
    closeAssignToGroupDialog: state => {
      if (!state.isMultiSelect) {
        state.assignedFacilities = [];
      }
      state.isAssignToGroupDialogOpen = false;
    },
    resetAssignToGroupDialog: () => initialState,
    toggleFacility: (state, action: PayloadAction<AssignedFacilityType>) => {
      state.isMultiSelect = true;

      const { facilityId } = action.payload;
      const index = state.assignedFacilities.findIndex(facility => facility.facilityId === facilityId);
      index === -1 ? state.assignedFacilities.push(action.payload) : state.assignedFacilities.splice(index, 1);
    },
    clearAssignedFacilities: state => {
      state.assignedFacilities = [];
    }
  }
});

export const {
  openAssignToGroupDialog,
  closeAssignToGroupDialog,
  toggleFacility,
  resetAssignToGroupDialog,
  clearAssignedFacilities
} = assignToGroupSlice.actions;

export default assignToGroupSlice.reducer;
