import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { Dispatch } from 'redux';

import { STATUS_FAILED, STATUS_INITIAL, STATUS_LOADING, STATUS_SUCCEEDED } from '../../../../../lib/constants';
import { PredefinedMessage, PredefinedMessageDialogType } from '../../../../../types';

interface InitialState {
  groupPredefinedMessagesDialog: {
    open: boolean;
    groupId: number | null;
    predefinedMessages: PredefinedMessage[];
    fetchStatus: string;
  };
  predefinedMessageDialog: {
    open: boolean;
    groupId: number | null;
    type: PredefinedMessageDialogType;
    predefinedMessage: PredefinedMessage | null;
  };
  removeConfirmationPredefinedMessageDialog: {
    open: boolean;
    groupId: number | null;
    predefinedMessageId: number | null;
  };
}

const initialState: InitialState = {
  groupPredefinedMessagesDialog: {
    open: false,
    groupId: null,
    predefinedMessages: [],
    fetchStatus: STATUS_INITIAL
  },
  predefinedMessageDialog: {
    open: false,
    groupId: null,
    type: PredefinedMessageDialogType.NEW,
    predefinedMessage: null
  },
  removeConfirmationPredefinedMessageDialog: {
    open: false,
    groupId: null,
    predefinedMessageId: null
  }
};

export const fetchGroupPredefinedMessages = createAsyncThunk(
  'groupPredefinedMessages/fetch',
  async (groupId: number) => {
    const { data } = await axios.get(`/api/facilities-groups/${groupId}/predefined-messages`);

    console.info(`fetchGroupPredefinedMessages: ${JSON.stringify(data)}`);

    return data;
  }
);

export const createGroupPredefinedMessage =
  (groupId: number, predefinedMessage: PredefinedMessage) => async (dispatch: Dispatch) => {
    axios.post(`/api/facilities-groups/${groupId}/predefined-messages`, predefinedMessage).then(res => {
      predefinedMessage.id = res.data.id;

      dispatch(appendGroupPredefinedMessage(predefinedMessage));
    });
  };

export const editGroupPredefinedMessage =
  (groupId: number, predefinedMessage: PredefinedMessage) => async (dispatch: Dispatch) => {
    axios
      .patch(`/api/facilities-groups/${groupId}/predefined-messages/${predefinedMessage.id}`, predefinedMessage)
      .then(() => {
        dispatch(modifyGroupPredefinedMessage(predefinedMessage));
      });
  };

export const removeGroupPredefinedMessage =
  (groupId: number, predefinedMessageId: number) => async (dispatch: Dispatch) => {
    axios.delete(`/api/facilities-groups/${groupId}/predefined-messages/${predefinedMessageId}`).then(() => {
      dispatch(deleteGroupPredefinedMessage(predefinedMessageId));
      dispatch(closeRemoveConfirmationPredefinedMessageDialog());
    });
  };

const groupPredefinedMessagesSlice = createSlice({
  name: 'groupPredefinedMessagesSlice',
  initialState,
  reducers: {
    openGroupPredefinedMessagesDialog: (state, action: PayloadAction<number>) => {
      state.groupPredefinedMessagesDialog = {
        open: true,
        fetchStatus: STATUS_LOADING,
        groupId: action.payload,
        predefinedMessages: []
      };
    },
    setGroupPredefinedMessages: (state, action: PayloadAction<PredefinedMessage[]>) => {
      state.groupPredefinedMessagesDialog.predefinedMessages = action.payload;
    },
    closeGroupPredefinedMessagesDialog: state => {
      state.groupPredefinedMessagesDialog = initialState.groupPredefinedMessagesDialog;
    },
    openNewGroupPredefinedMessageDialog: state => {
      state.predefinedMessageDialog = {
        open: true,
        type: PredefinedMessageDialogType.NEW,
        groupId: state.groupPredefinedMessagesDialog.groupId,
        predefinedMessage: null
      };
    },
    openEditGroupPredefinedMessageDialog: (state, action: PayloadAction<PredefinedMessage>) => {
      state.predefinedMessageDialog = {
        open: true,
        type: PredefinedMessageDialogType.EDIT,
        groupId: state.groupPredefinedMessagesDialog.groupId,
        predefinedMessage: action.payload
      };
    },
    closeGroupPredefinedMessageDialog: state => {
      state.predefinedMessageDialog = initialState.predefinedMessageDialog;
    },
    appendGroupPredefinedMessage: (state, action: PayloadAction<PredefinedMessage>) => {
      state.groupPredefinedMessagesDialog.predefinedMessages.push(action.payload);
    },
    modifyGroupPredefinedMessage: (state, action: PayloadAction<PredefinedMessage>) => {
      state.groupPredefinedMessagesDialog.predefinedMessages =
        state.groupPredefinedMessagesDialog.predefinedMessages.map(message =>
          message.id === action.payload.id ? { ...message, ...action.payload } : message
        );
    },
    openRemoveConfirmationPredefinedMessageDialog: (state, action: PayloadAction<number>) => {
      state.removeConfirmationPredefinedMessageDialog = {
        open: true,
        groupId: state.groupPredefinedMessagesDialog.groupId,
        predefinedMessageId: action.payload
      };
    },
    closeRemoveConfirmationPredefinedMessageDialog: state => {
      state.removeConfirmationPredefinedMessageDialog = initialState.removeConfirmationPredefinedMessageDialog;
    },
    deleteGroupPredefinedMessage: (state, action: PayloadAction<number>) => {
      state.groupPredefinedMessagesDialog.predefinedMessages =
        state.groupPredefinedMessagesDialog.predefinedMessages.filter(message => message.id !== action.payload);
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchGroupPredefinedMessages.pending, state => {
        state.groupPredefinedMessagesDialog.fetchStatus = STATUS_LOADING;
      })
      .addCase(fetchGroupPredefinedMessages.fulfilled, (state, action) => {
        state.groupPredefinedMessagesDialog.fetchStatus = STATUS_SUCCEEDED;
        state.groupPredefinedMessagesDialog.predefinedMessages = action.payload;
      })
      .addCase(fetchGroupPredefinedMessages.rejected, state => {
        state.groupPredefinedMessagesDialog.fetchStatus = STATUS_FAILED;
        state.groupPredefinedMessagesDialog.predefinedMessages = [];
      });
  }
});

export const {
  openGroupPredefinedMessagesDialog,
  closeGroupPredefinedMessagesDialog,
  openNewGroupPredefinedMessageDialog,
  openEditGroupPredefinedMessageDialog,
  setGroupPredefinedMessages,
  closeGroupPredefinedMessageDialog,
  appendGroupPredefinedMessage,
  openRemoveConfirmationPredefinedMessageDialog,
  closeRemoveConfirmationPredefinedMessageDialog,
  deleteGroupPredefinedMessage,
  modifyGroupPredefinedMessage
} = groupPredefinedMessagesSlice.actions;

export default groupPredefinedMessagesSlice.reducer;
