import { I18n } from 'react-redux-i18n';

import { OptionWithStoreType } from './types';

export const NEW_THEME_ID = -1;

export const NEW_BRAND_ID = -1;

export const NONE_BRAND_ID = -2;

export const storeTypePrefix = 'store_type_';
export const storeTypeEmotePrefix = 'store_type_emote_';
export const storeTypeOther = `${storeTypePrefix}other`;
export const storeTypeEmoteOther = `${storeTypeEmotePrefix}other`;

export const NONE_BRAND_OPTION: OptionWithStoreType = {
  label: 'None',
  value: NONE_BRAND_ID,
  storeType: I18n.t(storeTypeOther)
};
