import { FuseAnimate } from '@fuse';
import { Card } from '@material-ui/core';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';

import { getQueryData } from '../../lib/commonFunctions';
import { useDocumentTitle } from '../../utils/hooks/useDocumentTitle';
import { setSelectedView, setToken } from '../auth/store/LoginSlice';
import { logoutUser } from '../auth/store/actions';
import LoginCard from './LoginCard';
import { VIEW_INVITATION, VIEW_SET_NEW_PASSWORD } from './constants';

const useStyles = makeStyles(theme => ({
  root: {
    background:
      'radial-gradient(' + darken(theme.palette.primary.dark, 0.5) + ' 0%, ' + theme.palette.primary.dark + ' 80%)',
    backgroundImage: `url('${process.env.PUBLIC_URL}/assets/images/login/loginPageBackground.jpg')`,
    backgroundSize: 'cover',
    color: theme.palette.primary.contrastText
  },
  loginCard: {
    height: '750px'
  }
}));

function LoginApp(props) {
  useDocumentTitle('Login');
  const location = useLocation();
  const dispatch = useDispatch();

  const { success } = useSelector(state => state.login);

  const classes = useStyles();

  useEffect(() => {
    const queryData = getQueryData();

    if (localStorage.getItem('jwt_access_token_object') || success) {
      if ('token' in queryData) dispatch(logoutUser());
      props.history.push('/summary');
    }

    if ('token' in queryData) {
      dispatch(setToken(queryData.token));
      if (location.pathname === '/newPassword') dispatch(setSelectedView(VIEW_SET_NEW_PASSWORD));
      else if (location.pathname === '/invitation') dispatch(setSelectedView(VIEW_INVITATION));
    }
  }, [props.history, success, dispatch, location.pathname]);

  return (
    <div className={clsx(classes.root, 'flex flex-auto flex-shrink-0 flex-col items-center justify-center p-32')}>
      <div className='flex w-full flex-col items-center justify-center'>
        <FuseAnimate animation='transition.expandIn'>
          <Card className={clsx(classes.loginCard, 'w-full max-w-384')}>
            <LoginCard />
          </Card>
        </FuseAnimate>
      </div>
    </div>
  );
}

export default withRouter(LoginApp);
