import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type GroupId = number | null;
interface InitialState {
  isFacilityManagementDialogOpen: boolean;
  groupId: GroupId;
  isRemoveFacilityFromGroupDialogOpen: boolean;
  removedFacilityId: string | null;
}

const initialState: InitialState = {
  isFacilityManagementDialogOpen: false,
  groupId: null,
  isRemoveFacilityFromGroupDialogOpen: false,
  removedFacilityId: null
};

const facilityManagementSlice = createSlice({
  name: 'facilityManagement',
  initialState,
  reducers: {
    openFacilityManagementDialog: (state, action: PayloadAction<number>) => {
      state.isFacilityManagementDialogOpen = true;
      state.groupId = action.payload;
    },
    closeFacilityManagementDialog: () => initialState,
    openRemoveFacilityFromGroupDialog: (state, action: PayloadAction<string>) => {
      state.isRemoveFacilityFromGroupDialogOpen = true;
      state.removedFacilityId = action.payload;
    },
    closeRemoveFacilityFromGroupDialog: state => {
      state.isRemoveFacilityFromGroupDialogOpen = false;
      state.removedFacilityId = null;
    },
    setGroupId: (state, action: PayloadAction<GroupId>) => {
      state.groupId = action.payload;
    }
  }
});

export const {
  openFacilityManagementDialog,
  closeFacilityManagementDialog,
  closeRemoveFacilityFromGroupDialog,
  openRemoveFacilityFromGroupDialog,
  setGroupId
} = facilityManagementSlice.actions;

export default facilityManagementSlice.reducer;
