import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';

import { getFlag } from '../../../lib/languages';

const useStyles = makeStyles(() => ({
  flag: {
    display: 'inline',
    border: '1px solid #EDEDED'
  },
  rounded: {
    borderRadius: '4px'
  }
}));

export default function Flag({ lang = '', country = '', size = '1.6rem', rounded = false }) {
  const classes = useStyles();

  function getFlagSrc() {
    let flag = lang ? getFlag(lang) : country;
    return `${process.env.PUBLIC_URL}/assets/flags/${flag.toLowerCase()}.svg`;
  }

  return (
    <img
      className={clsx(classes.flag, {
        [classes.rounded]: rounded
      })}
      style={{ width: size }}
      alt='img'
      src={getFlagSrc()}
    />
  );
}
