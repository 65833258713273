import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { FetchingState } from 'types';

import { RESET_STORE } from '../actions/reduxActions';

interface InitialState {
  status: FetchingState;
  data: {
    addressName: string;
    city: string;
    group_id: number | null;
    country: string;
    demoObjectId: null;
    externalId: null;
    facilityType: string;
    googlePlaceId: string;
    group: null;
    id: string;
    infrastructure: null;
    instance: string;
    lang: string;
    latitude: number;
    longitude: number;
    object_name: string;
    phone: string;
    processId: number;
    status: string;
    street: string;
    streetNumber: string;
    supervisor: {
      email: string;
      id: null;
      name: string;
      phone: null;
      picture_path: null;
      surname: string;
    };
    supervisorId: null;
    zipCode: string;
  };
}

const initialState: InitialState = {
  status: FetchingState.INITIAL,
  data: {
    addressName: '',
    city: '',
    country: '',
    group_id: null,
    demoObjectId: null,
    externalId: null,
    facilityType: '',
    googlePlaceId: '',
    group: null,
    id: '',
    infrastructure: null,
    instance: '',
    lang: '',
    latitude: 0,
    longitude: 0,
    object_name: '',
    phone: '',
    processId: 0,
    status: '',
    street: '',
    streetNumber: '',
    supervisor: {
      email: '',
      id: null,
      name: '',
      phone: null,
      picture_path: null,
      surname: ''
    },
    supervisorId: null,
    zipCode: ''
  }
};

export const fetchFacilityData = createAsyncThunk('facility/fetch', async facilityId => {
  const response = await axios.get(`/api/facilities/${facilityId}`);
  return response.data;
});

const facilityDetailsSlice = createSlice({
  name: 'facilityDetails',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchFacilityData.pending, state => {
        state.status = FetchingState.LOADING;
      })
      .addCase(fetchFacilityData.fulfilled, (state, action) => {
        state.status = FetchingState.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(fetchFacilityData.rejected, state => {
        state.status = FetchingState.FAILED;
        state.data = initialState.data;
      });
    builder.addCase(RESET_STORE, () => initialState);
  }
});

export default facilityDetailsSlice.reducer;
