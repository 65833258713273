import { PayloadAction, createAsyncThunk, createSlice, unwrapResult } from '@reduxjs/toolkit';
import { Option } from 'app/common-components/inputs/types';
import axios from 'axios';
import { FetchingState } from 'types';

import {
  ButtonTheme,
  ButtonThemeFormProps,
  ButtonThemeListItem,
  ButtonThemeResource,
  ResourcesWithAndroidFonts,
  ThemeFile
} from '../types';
import { mapButtonThemeToFormProps } from '../utils/mapButtonThemeToFormProps';

export enum ThemeDialogMode {
  CREATE = 'create',
  EDIT = 'edit'
}

interface OpenButtonThemeDialogAction {
  type: ThemeDialogMode;
  id?: number;
}

interface InitialState {
  isButtonThemeDialogOpen: boolean;
  isDeleteConfirmationButtonThemeDialogOpen: boolean;
  themeDialogMode: ThemeDialogMode;
  currentTheme: {
    currentThemeFetchingState: FetchingState;
    currentThemeId?: number;
    theme?: ButtonThemeFormProps;
  };
  theme: {
    themeFetchingState: FetchingState;
    buttonThemes: ButtonThemeListItem[];
  };
  resources: ResourcesWithAndroidFonts & {
    resourcesFetchingState: FetchingState;
    uploadedFonts: ThemeFile[];
  };
}

const initialState: InitialState = {
  isButtonThemeDialogOpen: false,
  isDeleteConfirmationButtonThemeDialogOpen: false,
  themeDialogMode: ThemeDialogMode.CREATE,
  currentTheme: {
    currentThemeFetchingState: FetchingState.INITIAL,
    currentThemeId: undefined,
    theme: undefined
  },
  theme: {
    themeFetchingState: FetchingState.INITIAL,
    buttonThemes: []
  },
  resources: {
    resourcesFetchingState: FetchingState.INITIAL,
    fonts: [],
    images: [],
    uploadedFonts: []
  }
};

export const getButtonThemes = createAsyncThunk<ButtonThemeListItem[]>('buttonThemeSlice/themes ', async () => {
  const { data } = await axios.get('/api/buttons/themes');
  return data;
});

export const getResources = createAsyncThunk<ButtonThemeResource[]>('buttonThemeSlice/resources', async () => {
  const { data } = await axios.get('/api/resources');
  return data;
});

export const getAndroidFonts = createAsyncThunk<string[]>('buttonThemeSlice/androidFonts', async () => {
  const { data } = await axios.get('/api/resources/possible-fonts');
  return data;
});

export const getButtonTheme = createAsyncThunk<ButtonTheme, number>('buttonThemeSlice/theme', async (id: number) => {
  const { data } = await axios.get(`/api/buttons/themes/${id}`);
  return data;
});

export const getResourcesWithAndroidFonts = createAsyncThunk<ResourcesWithAndroidFonts>(
  'buttonThemeSlice/resourcesWithFonts',
  async (_, { dispatch }) => {
    const [resources, androidFonts] = await Promise.all([dispatch(getResources()), dispatch(getAndroidFonts())]);

    const unwrappedResources = unwrapResult(resources);
    const unwrappedAndroidFonts = unwrapResult(androidFonts);

    const resourcesFonts = unwrappedResources
      .filter(({ type }) => type === 'font')
      .map(({ name, id }) => ({ value: id.toString(), label: name }));

    const androidFontsResources = unwrappedAndroidFonts.map(name => ({ value: name, label: name }));

    return {
      fonts: [...androidFontsResources, ...resourcesFonts],
      images: unwrappedResources
        .filter(({ type }) => type === 'image')
        .map(({ name, id }) => ({ value: id.toString(), label: name }))
    };
  }
);

const buttonThemeSlice = createSlice({
  name: 'buttonSettings',
  initialState,
  reducers: {
    openButtonThemeDialog: (state, action: PayloadAction<OpenButtonThemeDialogAction>) => {
      state.isButtonThemeDialogOpen = true;
      state.themeDialogMode = action.payload.type;
      state.currentTheme.currentThemeId = action.payload.id;
    },
    closeButtonThemeDialog: state => {
      state.isButtonThemeDialogOpen = false;
      state.currentTheme.currentThemeId = undefined;
      state.currentTheme.theme = undefined;
      state.currentTheme.currentThemeFetchingState = FetchingState.INITIAL;
    },
    addFontToResources: (state, action: PayloadAction<Option>) => {
      state.resources.fonts.unshift(action.payload);
    },
    addFontToUploadedFonts: (state, action: PayloadAction<ThemeFile>) => {
      state.resources.uploadedFonts.push(action.payload);
    },
    addThemeToList: (state, action: PayloadAction<ButtonTheme>) => {
      const index = state.theme.buttonThemes.findIndex(theme => theme.id === action.payload.id);
      if (index !== -1) {
        state.theme.buttonThemes[index] = action.payload;
      } else {
        state.theme.buttonThemes.push(action.payload);
      }
    },
    openDeleteConfirmationButtonThemeDialog: (state, action: PayloadAction<number>) => {
      state.isDeleteConfirmationButtonThemeDialogOpen = true;
      state.currentTheme.currentThemeId = action.payload;
    },
    closeDeleteConfirmationButtonThemeDialog: state => {
      state.isDeleteConfirmationButtonThemeDialogOpen = false;
      state.currentTheme.currentThemeId = undefined;
    },
    deleteButtonTheme: (state, action: PayloadAction<number>) => {
      state.theme.buttonThemes = state.theme.buttonThemes.filter(theme => theme.id !== action.payload);
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getButtonThemes.pending, state => {
        state.theme.themeFetchingState = FetchingState.LOADING;
      })
      .addCase(getButtonThemes.fulfilled, (state, action) => {
        state.theme.themeFetchingState = FetchingState.SUCCEEDED;
        state.theme.buttonThemes = action.payload;
      })
      .addCase(getButtonThemes.rejected, state => {
        state.theme.themeFetchingState = FetchingState.FAILED;
      })
      .addCase(getResourcesWithAndroidFonts.pending, state => {
        state.resources.resourcesFetchingState = FetchingState.LOADING;
      })
      .addCase(getResourcesWithAndroidFonts.fulfilled, (state, action) => {
        state.resources.resourcesFetchingState = FetchingState.SUCCEEDED;
        state.resources.fonts = action.payload.fonts;
        state.resources.images = action.payload.images;
      })
      .addCase(getResourcesWithAndroidFonts.rejected, state => {
        state.resources.resourcesFetchingState = FetchingState.FAILED;
      })
      .addCase(getButtonTheme.pending, state => {
        state.currentTheme.currentThemeFetchingState = FetchingState.LOADING;
      })
      .addCase(getButtonTheme.fulfilled, (state, action) => {
        state.currentTheme.currentThemeFetchingState = FetchingState.SUCCEEDED;
        state.currentTheme.theme = mapButtonThemeToFormProps(action.payload);
      })
      .addCase(getButtonTheme.rejected, state => {
        state.currentTheme.currentThemeFetchingState = FetchingState.FAILED;
      });
  }
});

export const {
  closeButtonThemeDialog,
  openButtonThemeDialog,
  addFontToResources,
  addFontToUploadedFonts,
  addThemeToList,
  closeDeleteConfirmationButtonThemeDialog,
  openDeleteConfirmationButtonThemeDialog,
  deleteButtonTheme
} = buttonThemeSlice.actions;

export default buttonThemeSlice.reducer;
