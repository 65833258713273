import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { FetchingState } from 'types';

interface InitialState {
  brands: {
    id: number;
    name: string;
    button_theme_id: number | null;
    store_type: string | null;
  }[];
  brandFetchingState: FetchingState;
}

const initialState: InitialState = {
  brands: [],
  brandFetchingState: FetchingState.INITIAL
};

export const fetchBrands = createAsyncThunk('brands/fetchBrands', async () => {
  const { data } = await axios.get('/api/brands');
  return data;
});

const brandsSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {
    assignButtonThemeToBrand(state, action) {
      const { brandId, buttonThemeId } = action.payload;
      const brand = state.brands.find(({ id }) => id === brandId);
      if (brand) {
        brand.button_theme_id = buttonThemeId;
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchBrands.pending, state => {
      state.brandFetchingState = FetchingState.LOADING;
    });
    builder.addCase(fetchBrands.fulfilled, (state, action) => {
      state.brands = action.payload;
      state.brandFetchingState = FetchingState.SUCCEEDED;
    });
    builder.addCase(fetchBrands.rejected, state => {
      state.brandFetchingState = FetchingState.FAILED;
    });
  }
});

export const { assignButtonThemeToBrand } = brandsSlice.actions;

export default brandsSlice.reducer;
