import { PayloadAction, createAsyncThunk, createSlice, unwrapResult } from '@reduxjs/toolkit';
import axios from 'axios';
import { FetchingState } from 'types';

import { GroupDefines, GroupSettings } from './types';

interface InitialState extends Omit<GroupSettings, 'group_id' | 'themeButtonId'> {
  themeButtonId: number | null;
  group_id: number | null;
  name: string;
  groupFetchingState: FetchingState;
  isGroupSettingDialogOpen: boolean;
  groupDefinesFetchingState: FetchingState;
}

const initialState: InitialState = {
  isGroupSettingDialogOpen: false,
  groupDefinesFetchingState: FetchingState.INITIAL,
  buttonLanguages: '',
  name: '',
  group_id: null,
  groupFetchingState: FetchingState.INITIAL,
  is_bridge_enabled: false,
  is_recommending_facilities_enabled: false,
  is_rma_enabled: true,
  minutesUntilHidingMessages: null,
  themeButtonId: null,
  userMessageHidingTimeEnabled: null,
  userPrivacyEnabled: false,
  facilities: [],
  facilitiesInBridge: []
};

export const getGroupDefines = createAsyncThunk<GroupDefines, number>('groupDialog/getDefines', async id => {
  const { data } = await axios.get(`/api/facilities-groups/${id}/defines`);
  return data;
});

export const getFacilitiesInBridge = createAsyncThunk<string[], number>(
  'groupDialog/getFacilitiesInBridge',
  async id => {
    const { data } = await axios.get(`/api/facilities-groups/${id}/bridge-facilities`);
    return Object.keys(data.facilities);
  }
);

export const getGroupName = createAsyncThunk<{ name: string }, number>('groupDialog/getName', async id => {
  const { data } = await axios.get(`/api/facilities-groups/${id}`);
  return { name: data.name };
});

export const getGroupSettings = createAsyncThunk<GroupSettings, number>(
  'groupDialog/getGroup',
  async (id, { dispatch }) => {
    const definesResult = await dispatch(getGroupDefines(id));
    const nameResult = await dispatch(getGroupName(id));
    const facilitiesInBridgeResult = await dispatch(getFacilitiesInBridge(id));

    const defines = unwrapResult(definesResult);
    const { name } = unwrapResult(nameResult);
    const facilitiesInBridge = unwrapResult(facilitiesInBridgeResult);
    return { ...defines, name, facilitiesInBridge };
  }
);

const groupDialogSlice = createSlice({
  name: 'groupDialog',
  initialState,
  reducers: {
    openGroupSettingsDialog: (state, action: PayloadAction<{ group_id: number; name: string }>) => {
      state.isGroupSettingDialogOpen = true;
      state.group_id = action.payload.group_id;
      state.name = action.payload.name;
    },
    closeGroupSettingsDialog: () => initialState
  },
  extraReducers(builder) {
    builder
      .addCase(getGroupSettings.pending, state => {
        state.groupFetchingState = FetchingState.LOADING;
      })
      .addCase(getGroupSettings.fulfilled, (state, action) => {
        return { ...state, ...action.payload, groupFetchingState: FetchingState.SUCCEEDED };
      })
      .addCase(getGroupSettings.rejected, state => {
        state.groupFetchingState = FetchingState.FAILED;
      })
      .addCase(getGroupDefines.pending, state => {
        state.groupDefinesFetchingState = FetchingState.LOADING;
      })
      .addCase(getGroupDefines.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          group_id: state.group_id,
          groupDefinesFetchingState: FetchingState.SUCCEEDED
        };
      })
      .addCase(getGroupDefines.rejected, state => {
        state.groupDefinesFetchingState = FetchingState.FAILED;
      });
  }
});

export const { closeGroupSettingsDialog, openGroupSettingsDialog } = groupDialogSlice.actions;

export default groupDialogSlice.reducer;
