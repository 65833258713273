import Tooltip from '@material-ui/core/Tooltip';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { useTranslatorMode } from '../../custom-hooks/useTranslatorMode';
import EnhancedTranslate from '../EnhancedTranslate';

function TranslationTooltip({ title, children, disableHoverListener = false }) {
  const translatorModeEnabled = useSelector(({ common }) => common.common.translatorModeEnabled);
  const [getTooltipContent] = useTranslatorMode();

  return (
    <Fragment>
      {translatorModeEnabled && (
        <Tooltip title={getTooltipContent(title)} disableHoverListener={disableHoverListener}>
          {children}
        </Tooltip>
      )}
      {!translatorModeEnabled && (
        <Tooltip title={<EnhancedTranslate value={title} disableHoverListener={disableHoverListener} />}>
          {children}
        </Tooltip>
      )}
    </Fragment>
  );
}

export default TranslationTooltip;
