import _ from '@lodash';
import { useCallback, useState } from 'react';
import { GenericObject, SetNestedValue } from 'types';

function useForm<T extends GenericObject>(initialState?: T, onSubmit?: () => void) {
  const [form, setForm] = useState<T>(initialState ? _.cloneDeep(initialState) : ({} as T));

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setForm(prevForm => {
      const newForm = { ...prevForm };
      _.set(newForm, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value);
      return newForm;
    });
  }, []);

  const resetForm = useCallback(() => {
    setForm(initialState ? _.cloneDeep(initialState) : ({} as T));
  }, [initialState]);

  const setInForm: SetNestedValue<T> = useCallback((name, value) => {
    setForm(prevForm => {
      const newForm = _.cloneDeep(prevForm);
      _.set(newForm, name, value);
      return newForm;
    });
  }, []);

  const handleSubmit = useCallback(
    event => {
      if (event) {
        event.preventDefault();
      }
      if (onSubmit) {
        onSubmit();
      }
    },
    [onSubmit]
  );

  return {
    form,
    handleChange,
    handleSubmit,
    resetForm,
    setForm,
    setInForm
  };
}

export default useForm;
