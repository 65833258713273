import { NOT_GROUPED_ID } from 'app/main/apps/partner/constants';
import { useTypedSelector } from 'app/store/reducers/hooks/useTypedSelector';
import { DEFAULT_BRIDGE_ENABLED, DEFAULT_RECOMMENDING_FACILITIES_ENABLED, DEFAULT_RMA_ENABLED } from 'lib/constants';
import { useMemo } from 'react';
import { FetchingState } from 'types';

type GroupId = number | null;

interface GroupDefinesResult {
  isInGroup: boolean;
  groupId: GroupId;
  isLoading: boolean;
  isLoaded: boolean;
  isError: boolean;
  isRecommendingFacilitiesEnabled: boolean;
  isRmaEnabled: boolean;
  isBridgeEnabled: boolean;
}

const getFeatureState = (
  isLoaded: boolean,
  isInGroup: boolean,
  featureFlag: boolean,
  defaultValue: boolean
): boolean => {
  if (!isLoaded) return false;

  return isInGroup ? featureFlag : defaultValue;
};

export const useGroupDefines = (): GroupDefinesResult => {
  const { groupDefinesFetchingState, groupDefines } = useTypedSelector(({ common }) => common.common);
  const { group_id: groupId } = useTypedSelector(({ facilityDetails }) => facilityDetails.data);
  const { status: facilityDetailsFetchingStatus } = useTypedSelector(({ facilityDetails }) => facilityDetails);

  const isLoaded = useMemo(
    () => facilityDetailsFetchingStatus === FetchingState.SUCCEEDED,
    [facilityDetailsFetchingStatus]
  );

  const isInGroup = useMemo(
    () =>
      isLoaded &&
      ((groupDefinesFetchingState === FetchingState.SUCCEEDED &&
        groupDefines.group_id !== NOT_GROUPED_ID &&
        groupDefines.group_id !== null) ||
        groupId !== null),
    [groupDefines.group_id, groupDefinesFetchingState, groupId, isLoaded]
  );

  return {
    isInGroup,
    groupId,
    isLoading: groupDefinesFetchingState === FetchingState.LOADING,
    isLoaded,
    isError: groupDefinesFetchingState === FetchingState.FAILED,
    isRecommendingFacilitiesEnabled: getFeatureState(
      isLoaded,
      isInGroup,
      groupDefines.is_recommending_facilities_enabled,
      DEFAULT_RECOMMENDING_FACILITIES_ENABLED
    ),
    isRmaEnabled: getFeatureState(isLoaded, isInGroup, groupDefines.is_rma_enabled, DEFAULT_RMA_ENABLED),
    isBridgeEnabled: getFeatureState(isLoaded, isInGroup, groupDefines.is_bridge_enabled, DEFAULT_BRIDGE_ENABLED)
  };
};
