import _ from '@lodash';
import axios from 'axios';
import { I18n } from 'react-redux-i18n';
import { Dispatch } from 'redux';
import { Obid, Plmn, SimCards, Supervisor } from 'types';
import { Instance } from 'types';

import { isEnvDev, isFacilityCreatedAndCached } from '../../../../../../lib/commonFunctions';
import {
  LONG_ENDPOINT_TIMEOUT_60_SECONDS,
  STATUS_FAILED,
  STATUS_LOADING,
  STATUS_SUCCEEDED,
  UNRECOGNIZED_ERROR
} from '../../../../../../lib/constants';
import { getCurrentSelectedObid } from '../../../../../../lib/getObid';
import { store } from '../../../../../store';
import * as MessageActions from '../../../../../store/actions';
import { setObjects, showMessage } from '../../../../../store/actions';
import { getSettings } from '../../../settings/store/actions';
import {
  addManagerToList,
  fetchFacilityManagers,
  removeManagerFromList,
  setFacilityCreationStatus
} from '../PartnerSlice';
import {
  AddManager,
  AddNewFacility,
  AssignFacilitiesToGroupActionProps,
  EditFacilityDialogDataProps,
  FacilitiesStatisticsResponse,
  NestedFacilitiesStatistics
} from '../types';

export const GET_ENTITIES = '[PARTNER] GET ENTITIES';
export const SET_IS_FETCH_ENTITIES_LOADING = '[PARTNER] SET IS FETCH ENTITIES LOADING';
export const SET_SEARCH_TEXT = '[PARTNER] SET SEARCH TEXT';
export const OPEN_EDIT_FACILITY_DIALOG = '[PARTNER] OPEN EDIT FACILITY DIALOG';
export const CLOSE_EDIT_FACILITY_DIALOG = '[PARTNER] CLOSE EDIT FACILITY DIALOG';
export const ADD_ENTITY = '[PARTNER] ADD ENTITY';
export const UPDATE_ENTITY = '[PARTNER] UPDATE ENTITY';
export const EDIT_FACILITY_SUPERVISOR = 'EDIT FACILITY SUPERVISOR';
export const PAIR_DEVICES_OPEN_DIALOG = '[PARTNER] PAIR DEVICES OPEN DIALOG';
export const PAIR_DEVICES_CLOSE_DIALOG = '[PARTNER] PAIR DEVICES CLOSE DIALOG';
export const PAIR_DEVICES_BY_SERIALS_REPORT = '[PARTNER] PAIR DEVICES BY SERIALS REPORT';
export const CLOSE_PAIR_DEVICE_BY_SERIAL_REPORT_DIALOG = '[PARTNER] CLOSE PAIR DEVICE BY SERIAL REPORT DIALOG';
export const OPEN_ADD_MANAGERS_DIALOG = '[PARTNER] OPEN ADD MANAGERS DIALOG';
export const CLOSE_ADD_MANAGERS_DIALOG = '[PARTNER] CLOSE ADD MANAGERS DIALOG';
export const CLOSE_OBJECT_DETAILS_DRAWER = '[PARTNER] CLOSE OBJECT DETAILS DRAWER';
export const OPEN_MOBILE_NETWORK_OPERATORS_DIALOG = '[PARTNER] OPEN MOBILE NETWORK OPERATORS DIALOG';
export const CLOSE_MOBILE_NETWORK_OPERATORS_DIALOG = '[PARTNER] CLOSE MOBILE NETWORK OPERATORS DIALOG';
export const EDIT_PLMN_OF_OBJECT = '[PARTNER] EDIT PLMN OF OBJECT';
export const GET_CURRENTLY_USED_OPERATORS = '[PARTNER] GET CURRENTLY USED OPERATORS';
export const OPEN_OBJECT_DETAILS_DRAWER = '[PARTNER] OPEN OBJECT DETAILS DRAWER';
export const SET_GOOGLE_API_LOADING = '[PARTNER] SET GOOGLE API LOADING';
export const SET_SELECTED_PARTNER_ID = '[PARTNER] SET SELECTED PARTNER ID';
export const ASSIGN_FACILITIES_TO_GROUP = '[PARTNER] ASSIGN FACILITIES TO GROUP';
export const REMOVE_GROUP = '[PARTNER] REMOVE GROUP';
export const CHANGE_GROUP_NAME = '[PARTNER] CHANGE GROUP NAME';
export const IS_CREATED = '[PARTNER] IS CREATED';

export const getEntities = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_IS_FETCH_ENTITIES_LOADING,
      payload: true
    });
    const { data } = await axios.get<FacilitiesStatisticsResponse>('/api/facilities-statistics', {
      timeout: LONG_ENDPOINT_TIMEOUT_60_SECONDS
    });

    for (const row in data) {
      for (const facility of data[row].facilities) {
        if (!isFacilityCreatedAndCached(facility['status'])) {
          dispatch<any>(pollFacilityStatus(facility['id']));
        }
      }
    }

    const mappedData: NestedFacilitiesStatistics = _.mapValues(data, group => ({
      name: group.name,
      partner_id: group.partner_id,
      facilities: _.keyBy(group.facilities, 'id')
    }));

    dispatch({
      type: SET_IS_FETCH_ENTITIES_LOADING,
      payload: false
    });

    dispatch({
      type: GET_ENTITIES,
      payload: mappedData
    });
  };
};

export function getCurrentlyUsedOperators(obid: Obid) {
  const request = axios.get('/api/getCurrentlyUsedOperators', {
    params: {
      selectedObid: obid
    }
  });

  return (dispatch: Dispatch) => {
    request.then(response => {
      return dispatch({
        type: GET_CURRENTLY_USED_OPERATORS,
        payload: response.data
      });
    });
  };
}

export function addEntity(facility: AddNewFacility) {
  const request = axios.post('/api/facilities', {
    ...facility
  });

  return (dispatch: Dispatch) => {
    dispatch(setFacilityCreationStatus(STATUS_LOADING));
    request
      .then(response => {
        dispatch<any>(pollFacilityStatus(response.data.id));

        const userId = store.getState().auth.user.userId;
        setObjects(userId);
        dispatch(setFacilityCreationStatus(STATUS_SUCCEEDED));

        return dispatch({
          type: ADD_ENTITY,
          facility: response.data
        });
      })
      .catch(() => {
        dispatch(setFacilityCreationStatus(STATUS_FAILED));
      });
  };
}

export function openObjectDetailsDrawer(obid: Obid, groupId: number) {
  return {
    type: OPEN_OBJECT_DETAILS_DRAWER,
    obid,
    groupId
  };
}

export function closeObjectDetailsDrawer() {
  return {
    type: CLOSE_OBJECT_DETAILS_DRAWER
  };
}

export function updateFacility(facility: EditFacilityDialogDataProps) {
  return (dispatch: Dispatch) => {
    const requestsPromises = [];

    const isChangedFacilityEarlyAdopter = [Instance.INSTANCE_TYPE_DEV8, Instance.INSTANCE_TYPE_ALPHA].includes(
      facility.instance
    );

    let migrationRequest;
    if (isChangedFacilityEarlyAdopter && !facility.earlyAdopter) {
      const destinationInstance = isEnvDev() ? Instance.INSTANCE_TYPE_DEV : Instance.INSTANCE_TYPE_CLOUD;
      migrationRequest = axios.post('/api/objects/moveObjectToInfrastructure', {
        obid: facility.facilityId,
        to_instance_type: destinationInstance
      });
      facility.instance = destinationInstance;
    } else if (!isChangedFacilityEarlyAdopter && facility.earlyAdopter) {
      const destinationInstance = isEnvDev() ? Instance.INSTANCE_TYPE_DEV8 : Instance.INSTANCE_TYPE_ALPHA;
      migrationRequest = axios.post('/api/objects/moveObjectToInfrastructure', {
        obid: facility.facilityId,
        to_instance_type: destinationInstance
      });
      facility.instance = destinationInstance;
    }
    if (migrationRequest) requestsPromises.push(migrationRequest);

    const updateRequest = axios.patch(`/api/facilities/${facility.facilityId}`, {
      ...facility
    });

    requestsPromises.push(updateRequest);

    Promise.all(requestsPromises)
      .then(() => {
        if (String(facility.facilityId) === String(getCurrentSelectedObid())) getSettings(facility.facilityId);
        const userId = store.getState().auth.user.userId;
        setObjects(userId);
      })
      .catch(error => {
        console.error('Error during facility update: ', error);
      });

    return dispatch({
      type: UPDATE_ENTITY,
      entity: facility
    });
  };
}

export function editPlmnOfObject(obid: Obid, plmn: Plmn, groupId: number) {
  return (dispatch: Dispatch) => {
    axios
      .patch(`/api/facilities/${obid}`, {
        plmn
      })
      .then(() => {
        dispatch(
          MessageActions.showMessage({
            message: I18n.t('The operator selection has been changed'),
            variant: 'success'
          })
        );
      });

    return dispatch({
      type: EDIT_PLMN_OF_OBJECT,
      groupId,
      obid,
      plmn
    });
  };
}

export function addManager(newEntity: AddManager, facilityId: number) {
  return (dispatch: Dispatch) => {
    axios
      .post('/api/dashboard-users', {
        ...newEntity,
        facilityId: facilityId,
        selectedObid: getCurrentSelectedObid()
      })
      .then(response => {
        dispatch(
          addManagerToList({
            ...response.data,
            email: newEntity.email,
            role: newEntity.role
          })
        );
        dispatch(
          showMessage({
            message: I18n.t('Invitation sent'),
            variant: 'success',
            autoHideDuration: 3000
          })
        );
      })
      .catch(() => {
        dispatch(
          showMessage({
            message: I18n.t(UNRECOGNIZED_ERROR),
            variant: 'error',
            autoHideDuration: 3000
          })
        );
      });
  };
}

export function pairDeviceOpenDialog(obid: Obid) {
  return {
    type: PAIR_DEVICES_OPEN_DIALOG,
    obid
  };
}

export function openMobileNetworkOperatorsDialog(
  obid: Obid,
  country: string,
  simCards: SimCards,
  plmn: Plmn,
  groupId: number
) {
  return {
    type: OPEN_MOBILE_NETWORK_OPERATORS_DIALOG,
    obid,
    country,
    simCards,
    plmn,
    groupId
  };
}

export function closeMobileNetworkOperatorsDialog() {
  return {
    type: CLOSE_MOBILE_NETWORK_OPERATORS_DIALOG
  };
}

export function pairDeviceCloseDialog() {
  return {
    type: PAIR_DEVICES_CLOSE_DIALOG
  };
}

export function openAddManagersDialog() {
  return {
    type: OPEN_ADD_MANAGERS_DIALOG
  };
}

export function closeAddManagersDialog() {
  return {
    type: CLOSE_ADD_MANAGERS_DIALOG
  };
}

export function setSearchText(value: string) {
  return {
    type: SET_SEARCH_TEXT,
    searchText: value
  };
}

export function openEditFacilityDialog(data: EditFacilityDialogDataProps) {
  return { type: OPEN_EDIT_FACILITY_DIALOG, data };
}

export function closeEditFacilityDialog() {
  return {
    type: CLOSE_EDIT_FACILITY_DIALOG
  };
}

export function removeManagers(entityIds: string | number | string[] | number[], facilityId: number) {
  const request = axios.post('/api/dashboardUsers/remove', {
    selectedObid: facilityId,
    uids: entityIds
  });

  return (dispatch: Dispatch) => {
    request.then(() => {
      fetchFacilityManagers(facilityId, false);
      dispatch(showMessage({ message: I18n.t('Manager was removed'), variant: 'success' }));
      if (Array.isArray(entityIds)) {
        dispatch(removeManagerFromList(entityIds[0]));
      }
    });
  };
}

export function removeManagerFromGroup(groupId: number, managerId: number) {
  const request = axios.delete(`/api/facilities-groups/${groupId}/managers/${managerId}`);
  return (dispatch: Dispatch) => {
    request.then(() => {
      dispatch(removeManagerFromList(managerId));
      dispatch(showMessage({ message: I18n.t('Manager removed from group'), variant: 'success' }));
    });
  };
}

export function closePairDeviceBySerialReportDialog() {
  return {
    type: CLOSE_PAIR_DEVICE_BY_SERIAL_REPORT_DIALOG
  };
}

export function setSelectedPartnerId(partnerId: string | number) {
  return {
    type: SET_SELECTED_PARTNER_ID,
    payload: partnerId
  };
}

export function pairDevicesBySerials(serials: string[], partnerSelectedObid: Obid) {
  return (dispatch: Dispatch) => {
    axios
      .post('/api/serials/assignSerialsToObjectAndGetReport', {
        selectedObid: getCurrentSelectedObid(),
        targetObid: partnerSelectedObid,
        serials
      })
      .then(response => {
        dispatch({
          type: PAIR_DEVICES_BY_SERIALS_REPORT,
          payload: response.data
        });
      });
  };
}

export function editFacilitySupervisor(facilityId: string, supervisor: Supervisor, groupId: number) {
  return {
    type: EDIT_FACILITY_SUPERVISOR,
    payload: {
      facilityId,
      supervisor,
      groupId
    }
  };
}

export function assignFacilitiesToGroup({
  assignedFacilities,
  groupId,
  groupName,
  partnerId
}: AssignFacilitiesToGroupActionProps) {
  return {
    type: ASSIGN_FACILITIES_TO_GROUP,
    payload: {
      groupId,
      groupName,
      assignedFacilities,
      partnerId
    }
  };
}

export function removeGroup(groupId: number) {
  return {
    type: REMOVE_GROUP,
    payload: groupId
  };
}

export function changeGroupName({ groupId, groupName }: { groupId: number; groupName: string }) {
  return {
    type: CHANGE_GROUP_NAME,
    payload: {
      groupId,
      groupName
    }
  };
}

export const pollFacilityStatus = (obid: Obid) => {
  return (dispatch: Dispatch) => {
    const intervalId = setInterval(async () => {
      const { data } = await axios.get(`/api/facilities/${obid}/is-created`);

      if (data.is_created) {
        clearInterval(intervalId);

        const userId = store.getState().auth.user.userId;
        dispatch<any>(setObjects(userId));

        dispatch({
          type: IS_CREATED,
          obid: obid
        });
      }
    }, 5000);
  };
};
