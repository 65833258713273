import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface InitialState {
  isRemoveGroupDialogOpen: boolean;
  groupId: number | null;
}

const initialState: InitialState = {
  isRemoveGroupDialogOpen: false,
  groupId: null
};

const removeGroupSlice = createSlice({
  name: 'removeGroup',
  initialState,
  reducers: {
    openRemoveGroupDialog: (state, action: PayloadAction<number>) => {
      state.isRemoveGroupDialogOpen = true;
      state.groupId = action.payload;
    },
    closeRemoveGroupDialog: () => initialState
  }
});

export const { openRemoveGroupDialog, closeRemoveGroupDialog } = removeGroupSlice.actions;

export default removeGroupSlice.reducer;
