import { FuseScrollbars, FuseUtils } from '@fuse';
import _ from '@lodash';
import { CircularProgress } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import ComputerIcon from '@material-ui/icons/Computer';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import TabletIcon from '@material-ui/icons/Tablet';
import WatchIcon from '@material-ui/icons/Watch';
import BottleIcon from 'app/common-components/Icons/BottleIcon';
import DeviceAlarmDialog from 'app/main/apps/devices/devices/Dialogs/DeviceAlarmDialog';
import * as Actions from 'app/main/apps/devices/store/actions/devices.actions.js';
import { getTranslationKeyForDeviceType } from 'app/shared/functions/getTranslationKeyForDeviceType';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { useIsTableResizable } from 'utils/hooks/useIsTableResizable';

import batteryChargingIcon from '../../../../../../assets/new-icons/battery-charging.svg';
import batteryEmptyIcon from '../../../../../../assets/new-icons/battery-empty.svg';
import batterFullIcon from '../../../../../../assets/new-icons/battery-full.svg';
import batteryHalfIcon from '../../../../../../assets/new-icons/battery-half.svg';
import batteryLowIcon from '../../../../../../assets/new-icons/battery-low.svg';
import batteryMostlyGoodIcon from '../../../../../../assets/new-icons/battery-quater.svg';
import cellularEmptyErasedIcon from '../../../../../../assets/new-icons/cellular-empty.erased.svg';
import cellularEmptyIcon from '../../../../../../assets/new-icons/cellular-empty.svg';
import cellularFullIcon from '../../../../../../assets/new-icons/cellular-full.svg';
import cellularHalfIcon from '../../../../../../assets/new-icons/cellular-half.svg';
import cellularLowIcon from '../../../../../../assets/new-icons/cellular-low.svg';
import cellularQuaterIcon from '../../../../../../assets/new-icons/cellular-quater.svg';
import wifiEmptyErasedIcon from '../../../../../../assets/new-icons/wifi-empty.erased.svg';
import wifiEmptyIcon from '../../../../../../assets/new-icons/wifi-empty.svg';
import wifiFullIcon from '../../../../../../assets/new-icons/wifi-full.svg';
import wifiHalfIcon from '../../../../../../assets/new-icons/wifi-half.svg';
import wifiLowIcon from '../../../../../../assets/new-icons/wifi-low.svg';
import wifiMostlyGoodIcon from '../../../../../../assets/new-icons/wifi-quater.svg';
import {
  HumanizedTimeElapsedFromEpoch,
  getTimestampFromScreenshotFname,
  isOnline
} from '../../../../../../lib/commonFunctions';
import { PAGE_SIZE, SETTING_MIN_BAT_PCT_TO_BE_FULLY_READY, SETTING_TYPE_STRING } from '../../../../../../lib/constants';
import '../../../../../../styles/global-styles.scss';
import { getPageRowForSelection } from '../../../../../../utils/funcs/get-page-row-for-selection';
import { useIntervalTimeSelect } from '../../../../../../utils/hooks/useIntervalTimeSelect';
import EnhancedTranslate from '../../../../../common-components/EnhancedTranslate';
import GoogleScriptsWrapper from '../../../../../common-components/GoogleScriptsWrapper';
import useChangeFacility from '../../../../../custom-hooks/useChangeFacilityEffect';
import { useReactTableTranslations } from '../../../../../custom-hooks/useReactTableTranslations';
import { useSetting } from '../../../../../custom-hooks/useSetting';
import {
  DEVICE_TYPE_BUTTON,
  DEVICE_TYPE_RVM,
  DEVICE_TYPE_TABLET,
  DEVICE_TYPE_UNRECOGNIZED,
  DEVICE_TYPE_WATCH,
  DEVICE_TYPE_WEBCHAT,
  deviceHasAnyTypeValid,
  filterByType,
  getDeviceType,
  isButton,
  isNumber,
  isRvm,
  isWatch
} from '../../../../../shared/functions/device-type';
import * as ForSelectActions from '../../../../../store/actions';
import { AddDevicesFromPackageModal } from '../../../addDevicesFromPackage/AddDevicesFromPackageModal';
import AddNewDevicesDialog from '../Dialogs/AddNewDevicesDialog';
import AddNewDevicesReportDialog from '../Dialogs/AddNewDevicesReportDialog';
import RestartAppDialog from '../Dialogs/AppRestartDialog';
import ButtonSettingsDialog from '../Dialogs/ButtonSettingsDialog';
import RestartDeviceDialog from '../Dialogs/DeviceRestartDialog';
import DeviceScreenshotDialog from '../Dialogs/DeviceScreenshotDialog';
import DownloadLogsDialog from '../Dialogs/DownloadLogs/DownloadLogsDialog';
import DeviceLocationDialog from '../Dialogs/Location/DeviceLocationDialog';
import RemoveDeviceFromObjectDialog from '../Dialogs/RemoveDeviceFromObjectDialog';
import RvmSettingsDialog from '../Dialogs/rvm/RvmSettingsDialog';
import AssignedUserChip from './AssignedUserChip';
import BatteryChargingTooltip from './BatteryChargingTooltip';
import DeviceTableActions from './actions/DeviceTableActions';

const NO_APPLICABLE = 2;

function LastSeen({ lastSeenTime }) {
  return (
    <div className='text-center'>
      <p>{I18n.t('Last seen')}</p>
      <p>{HumanizedTimeElapsedFromEpoch(lastSeenTime / 10000)}</p>
    </div>
  );
}

function LastLogged({ fullName }) {
  return (
    <div className='text-center'>
      <p>{I18n.t('Logged')}</p>
      <p>{fullName}</p>
    </div>
  );
}

function SerialWithName({ serial, name }) {
  return (
    <div className='text-center'>
      <p>{serial}</p>
      <p>{name}</p>
    </div>
  );
}

function WarningEmoji() {
  return (
    <span role='img' aria-label='warning'>
      ⚠️
    </span>
  );
}

export function isButtonNotConfigured(device) {
  if (!device || !device.buttonDetails) return true;
  return Object.keys(device.buttonDetails).length === 0;
}

export function getDescStatus(device, minBatPctToBeFullyReady = 80) {
  if (_.isEmpty(device.deviceShadowReported)) {
    return I18n.t('No connection');
  }
  if (isButton(device)) {
    if (isOnline(device)) {
      if (isButtonNotConfigured(device)) return I18n.t('Configuration required');
      else return I18n.t('buttonClicked', { count: device.buttonDetails.clicksCount });
    } else {
      if (device.lastSeenTime) return <LastSeen lastSeenTime={device.lastSeenTime} />;
      else {
        return <div>-</div>;
      }
    }
  } else {
    try {
      if (isOnline(device)) {
        if (-1 !== device.deviceShadowReported?.uId) return <LastLogged fullName={device.fullName} />;
        if (device.deviceShadowReported?.onAc === 1) {
          if (device.deviceShadowReported?.battery?.level < minBatPctToBeFullyReady)
            return I18n.t('Charging is taking place');
          else return I18n.t('Charged');
        }
        return I18n.t('Unlogged');
      } else if (device.lastSeenTime) return <LastSeen lastSeenTime={device.lastSeenTime} />;
      else return <div>-</div>;
    } catch (e) {
      console.error('DevicesTable.getDescStatus(), exc:', e);
    }
  }
}

export function getDescStatusJustText(device, minBatPctToBeFullyReady = 80) {
  if (isButton(device)) {
    if (isOnline(device)) {
      return I18n.t('buttonClicked', { count: device.buttonClickedCount });
    } else {
      return I18n.t('Last seen') + ' ' + HumanizedTimeElapsedFromEpoch(device.lastSeenTime / 10000);
    }
  } else {
    try {
      if (isOnline(device)) {
        if (-1 !== device.deviceShadowReported?.uId) return I18n.t('Logged') + ' ' + device.function;
        if (device.deviceShadowReported?.onAc === 1) {
          if (device.deviceShadowReported?.battery?.level < minBatPctToBeFullyReady)
            return I18n.t('Charging is taking place');
          else return I18n.t('Charged');
        }
        return I18n.t('Unlogged');
      } else return I18n.t('Last seen') + ' ' + HumanizedTimeElapsedFromEpoch(device.lastSeenTime / 10000);
    } catch (e) {
      console.error('DevicesTable.getDescStatusJustText(), exc:', e);
    }
  }
}

const FILTER_TYPE_ONLINE = 1;
const FILTER_TYPE_OFFLINE = 2;

function DevicesTable() {
  const dispatch = useDispatch();
  const pageSizeChangedByUser = React.useRef(false);
  const reactTableTranslations = useReactTableTranslations();

  const devices = useSelector(({ devices }) => devices.devices.data);
  const devicesPanelMode = useSelector(({ devices }) => devices.devices.devicesPanelMode);
  const loading = useSelector(({ devices }) => devices.devices.loading);
  const searchText = useSelector(({ devices }) => devices.devices.searchText);
  const devicesTypeToShow = useSelector(({ devices }) => devices.devices.devicesTypeToShow);
  const latestVersion = useSelector(({ devices }) => devices.devices.latestVersion);
  const buttonLatestVersion = useSelector(({ devices }) => devices.devices.buttonLatestVersion);
  const selectedDeviceSerial = useSelector(({ devices }) => devices.devices.selectedDeviceSerial);
  const deviceScreenShotDialogOpen = useSelector(({ devices }) => devices.devices.deviceScreenshotDialogOpen);
  const latestAvailableScreenshot = useSelector(({ devices }) => devices.devices.latestAvailableScreenshot);
  const lastGetScreenshotRequestTimestamp = useSelector(
    ({ devices }) => devices.devices.lastGetScreenshotRequestTimestamp
  );

  const [minBatPctToBeFullyReady] = useSetting(SETTING_MIN_BAT_PCT_TO_BE_FULLY_READY, SETTING_TYPE_STRING, '80');
  const { headerClassName, isResizable } = useIsTableResizable();

  const [filteredData, setFilteredData] = useState(null);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  useChangeFacility(() => {
    dispatch(ForSelectActions.getUsers());
  });

  useEffect(() => {
    function filterOnlineOffline(devices, type) {
      return type === FILTER_TYPE_ONLINE ? devices.filter(isOnline) : devices.filter(isOffline);
    }

    function isOffline(device) {
      return !isOnline(device);
    }

    function getFilteredArray(entities, searchText) {
      const arr = Object.keys(entities).map(id => entities[id]);
      return FuseUtils.filterDevicesByString(arr, searchText);
    }

    if (devices) {
      let filteredData = { ...devices };

      for (let key in filteredData) {
        if (!filteredData[key]['shortSerial']) filteredData[key]['shortSerial'] = shortSerial(filteredData[key]);
      }

      filteredData = getFilteredArray(devices, searchText);

      if ([FILTER_TYPE_ONLINE, FILTER_TYPE_OFFLINE].includes(devicesPanelMode))
        filteredData = filterOnlineOffline(filteredData, devicesPanelMode);

      filteredData = filterByType(filteredData, devicesTypeToShow);

      setFilteredData(filteredData);
    }
  }, [devices, searchText, devicesPanelMode, devicesTypeToShow]);

  useEffect(() => {
    if (pageSizeChangedByUser.current) {
      return;
    }
    if (filteredData != null) {
      if (filteredData.length > 0 && filteredData.length <= 25) {
        setPageSize(filteredData.length);
      } else if (filteredData.length > 25) {
        setPageSize(25);
      }
    }
  }, [filteredData]);

  useIntervalTimeSelect(
    () => {
      dispatch(Actions.getDevices(false));
    },
    60,
    'sec'
  );

  useIntervalTimeSelect(
    () => {
      if (selectedDeviceSerial && deviceScreenShotDialogOpen) {
        if (lastGetScreenshotRequestTimestamp > getTimestampFromScreenshotFname(latestAvailableScreenshot))
          dispatch(Actions.getLatestAvailableScreenshot(selectedDeviceSerial, lastGetScreenshotRequestTimestamp));
      }
    },
    1,
    'sec'
  );

  if (loading || filteredData === null) {
    return (
      <div className='flex flex-1 items-center justify-center h-full'>
        <CircularProgress />
      </div>
    );
  }

  const setBatteryIcon = batteryLevel => {
    if (batteryLevel >= 80) return batterFullIcon;
    if (batteryLevel > 65) return batteryMostlyGoodIcon;
    if (batteryLevel > 30) return batteryHalfIcon;
    if (batteryLevel > 5) return batteryLowIcon;
    return batteryEmptyIcon;
  };

  const setCellularIcon = dBm => {
    if (dBm < -116) return cellularEmptyIcon;
    if (dBm < -111) return cellularLowIcon;
    if (dBm < -105) return cellularHalfIcon;
    if (dBm < -100) return cellularQuaterIcon;
    return cellularFullIcon;
  };

  const setWifiIcon = dBm => {
    if (dBm < -88) return wifiEmptyIcon;
    if (dBm < -83) return wifiLowIcon;
    if (dBm < -76) return wifiHalfIcon;
    if (dBm < -60) return wifiMostlyGoodIcon;
    return wifiFullIcon;
  };

  function shortSerial(device) {
    const serial = device.serial;
    if (serial) {
      if (isButton(device)) return serial;
      if (serial.length > 8) {
        if (isNumber(serial)) {
          const short = serial.slice(-6);
          return short.substring(0, 3) + '-' + short.substring(3);
        } else {
          return '*' + serial.slice(-8);
        }
      }
    }
    return serial;
  }

  function isWatchVersionUpToDate(version) {
    if (!version || !latestVersion) return NO_APPLICABLE;
    return version === latestVersion;
  }

  function isButtonVersionUpToDate(version) {
    if (!version || !buttonLatestVersion) return NO_APPLICABLE;
    return version === buttonLatestVersion;
  }

  function isAppVersionUpToDate(device) {
    const build = device.deviceShadowReported?.build;

    switch (getDeviceType(device)) {
      case DEVICE_TYPE_WATCH:
        return isWatchVersionUpToDate(build);
      case DEVICE_TYPE_BUTTON:
        return isButtonVersionUpToDate(build);
      default:
        return true;
    }
  }

  const getTrProps = (_, rowInfo) => {
    if (rowInfo) {
      if (!isOnline(rowInfo.original)) {
        return {
          style: {
            opacity: 0.4
          }
        };
      }
    }
    return {};
  };

  function getDeviceIcon(device) {
    switch (getDeviceType(device)) {
      case DEVICE_TYPE_WATCH:
        return <WatchIcon />;
      case DEVICE_TYPE_WEBCHAT:
        return <ComputerIcon />;
      case DEVICE_TYPE_TABLET:
        return <TabletIcon />;
      case DEVICE_TYPE_BUTTON:
        return <RadioButtonCheckedIcon />;
      case DEVICE_TYPE_UNRECOGNIZED:
        return <HelpOutlineIcon />;
      case DEVICE_TYPE_RVM:
        return <BottleIcon className='text-28' />;
      default:
        return <HelpOutlineIcon />;
    }
  }

  const getBatteryValue = device => {
    const minNumber = Number.MIN_SAFE_INTEGER;

    if (isButton(device)) return isOnline(device) ? minNumber + 2 : minNumber + 1;
    if (isRvm(device)) return minNumber + 1;

    if (device.deviceShadowReported?.battery?.mV === undefined) return minNumber;

    const { mV, level } = device.deviceShadowReported?.battery;

    if (device.deviceShadowReported?.onAc) {
      return mV && mV > 0 ? level + Number.MAX_SAFE_INTEGER / 2 : minNumber + 4;
    }

    return mV && mV > 0 ? level : minNumber + 3;
  };

  return (
    <React.Fragment>
      <div className='w-full flex flex-col'>
        <FuseScrollbars className='flex-grow overflow-x-auto'>
          <ReactTable
            className='-striped -highlight h-full firefox-height-react-table live-panel-users-table box-shadow-main slide-in-fwd-center'
            page={page}
            onPageChange={state => setPage(state)}
            onPageSizeChange={state => {
              pageSizeChangedByUser.current = true;
              setPage(0);
              setPageSize(state);
            }}
            pageSize={pageSize}
            pages={Math.ceil(filteredData.length / pageSize)}
            defaultPageSize={pageSize}
            pageSizeOptions={getPageRowForSelection(filteredData)}
            data={filteredData}
            {...reactTableTranslations}
            getTrProps={getTrProps}
            resizable={isResizable}
            columns={[
              {
                Header: <EnhancedTranslate value='Online' />,
                id: 'online',
                width: 70,
                key: device => device.serial,
                accessor: device => (isOnline(device) ? 1 : 0),
                Cell: row => {
                  if (isRvm(row.original)) return <span>-</span>;
                  const textColor = isOnline(row.original) ? '#07be03' : '#acacac';
                  if (isOnline(row.original))
                    return (
                      <Tooltip title={HumanizedTimeElapsedFromEpoch(row.original.lastSeenTime / 10000)}>
                        <span style={{ color: textColor, fontSize: '35px' }}>&bull;</span>
                      </Tooltip>
                    );
                  else return <span style={{ color: textColor, fontSize: '35px' }}>&bull;</span>;
                },
                headerClassName
              },
              {
                Header: <EnhancedTranslate value='Type' />,
                id: 'type',
                width: 70,
                accessor: device => getDeviceType(device),
                Cell: row => getDeviceIcon(row.original),
                headerClassName
              },
              {
                Header: <EnhancedTranslate value='Serial' />,
                id: 'serial',
                width: 170,
                sortable: false,
                accessor: device => {
                  const displaySerialWithName = deviceHasAnyTypeValid(device, [DEVICE_TYPE_BUTTON, DEVICE_TYPE_RVM]);
                  const serial = device.serial || device.external_id;
                  return (
                    <Tooltip title={serial}>
                      <span
                        onClick={() => {
                          navigator.clipboard.writeText(serial);
                        }}
                        style={{ whiteSpace: 'break-spaces' }}
                      >
                        <div>{!displaySerialWithName && device.shortSerial}</div>
                        {displaySerialWithName && (
                          <SerialWithName serial={serial} name={device.buttonDetails?.name || device.name} />
                        )}
                      </span>
                    </Tooltip>
                  );
                },
                headerClassName
              },
              {
                Header: <EnhancedTranslate value='Assigned_singular' />,
                id: 'assigned',
                width: 170,
                sortable: false,
                Cell: row => {
                  const device = row.original;
                  if (isWatch(device)) return <AssignedUserChip serial={device.serial} userId={device.assignedUser} />;
                  return <span>-</span>;
                },
                headerClassName
              },
              {
                Header: <EnhancedTranslate value='Battery' />,
                id: 'battery',
                width: 125,
                accessor: getBatteryValue,
                Cell: row => {
                  const device = row.original;
                  if ((isButton(device) && !isOnline(device)) || isRvm(device)) return <span>-</span>;
                  if (device.deviceShadowReported?.battery?.mV === undefined) return <></>;
                  if (device.deviceShadowReported?.battery?.mV === 0)
                    return <div>{1 === device.deviceShadowReported?.onAc && <BatteryChargingTooltip />}</div>;
                  const batDetails = (
                    <span>
                      {Math.round(device.deviceShadowReported?.battery?.mV / 10) / 100} V<br />
                      {device.deviceShadowReported?.battery?.temp / 10} °C
                    </span>
                  );
                  if (1 === device.deviceShadowReported?.onAc)
                    return (
                      <Tooltip title={batDetails}>
                        <div className='flex items-center'>
                          &nbsp;
                          <img src={setBatteryIcon(device.deviceShadowReported?.battery?.level)} alt='battery-level' />
                          &nbsp;
                          <img alt='' src={batteryChargingIcon} />
                          &nbsp;
                          <span
                            style={{
                              color: 'grey',
                              fontSize: '11px'
                            }}
                          >
                            {device.deviceShadowReported?.battery.level}%
                          </span>
                        </div>
                      </Tooltip>
                    );
                  else
                    return (
                      <Tooltip title={batDetails}>
                        <div className='flex items-center'>
                          &nbsp;
                          <img src={setBatteryIcon(device.deviceShadowReported.battery?.level)} alt='battery-level' />
                          &nbsp;
                          <img alt='' src={batteryChargingIcon} style={{ visibility: 'hidden' }} />
                          &nbsp;
                          <span
                            style={{
                              color: 'grey',
                              fontSize: '11px'
                            }}
                          >
                            {device.deviceShadowReported?.battery.level}%
                          </span>
                        </div>
                      </Tooltip>
                    );
                },
                headerClassName
              },
              {
                Header: <EnhancedTranslate value='WiFi' />,
                id: 'wifi',
                width: 70,
                accessor: device => {
                  if (!device.deviceShadowReported?.wifi) {
                    return Number.MIN_SAFE_INTEGER;
                  }
                  const { ssid, dBm } = device.deviceShadowReported?.wifi;
                  return ssid && ssid !== 'n/a' ? dBm : Number.MIN_SAFE_INTEGER + 1;
                },
                Cell: row => {
                  const shadow = row.original.deviceShadowReported;
                  if (isRvm(row.original)) return <span>-</span>;
                  if (shadow?.wifi?.ssid === undefined) return <></>;
                  if (shadow?.wifi?.ssid === 'n/a') return <img alt='' src={wifiEmptyErasedIcon} />;
                  return (
                    <Tooltip
                      title={
                        <span>
                          {shadow?.wifi.dBm} dBm
                          <br />
                          bssid: {shadow?.wifi?.bssid}
                          <br />
                          mac: {shadow?.wifi?.mac}
                          <br />
                          ip: {shadow?.wifi?.ip}
                        </span>
                      }
                    >
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={setWifiIcon(shadow?.wifi.dBm)} alt='wifi' />
                      </div>
                    </Tooltip>
                  );
                },
                headerClassName
              },
              {
                Header: <EnhancedTranslate value='Cellular network' />,
                id: 'range',
                width: 100,
                accessor: device => {
                  if (!device.deviceShadowReported?.cell) {
                    return Number.MIN_SAFE_INTEGER;
                  }
                  const { cid, dBm } = device.deviceShadowReported?.cell;
                  return cid && cid > -1 ? dBm : Number.MIN_SAFE_INTEGER + 1;
                },
                Cell: row => {
                  if (isRvm(row.original)) return <span>-</span>;
                  if (undefined === row.original.deviceShadowReported?.cell?.cid) return <></>;
                  if (-1 === row.original.deviceShadowReported?.cell?.cid)
                    return <img src={cellularEmptyErasedIcon} alt='cellular' />;
                  const turnedOnTimes = row.original.deviceShadowReported?.cell?.turnedOnTimes;
                  return (
                    <Tooltip
                      title={
                        <span>
                          {row.original.deviceShadowReported?.cell?.dBm} dBm
                          <br />
                          cid: {row.original.deviceShadowReported?.cell?.cid}
                          <br />
                          reconnects: {null != turnedOnTimes ? turnedOnTimes : 'n/a'}
                        </span>
                      }
                    >
                      <div style={{ display: 'flex', justifyContect: 'center' }}>
                        <img src={setCellularIcon(row.original.deviceShadowReported?.cell?.dBm)} alt='cellular' />
                        <div
                          style={{
                            marginTop: '5px',
                            marginLeft: '5px',
                            fontSize: '11px'
                          }}
                        >
                          {row.original.deviceShadowReported?.cell?.gen != null &&
                          row.original.deviceShadowReported?.cell?.gen !== 0
                            ? `${row.original.deviceShadowReported?.cell?.gen}G`
                            : ''}
                        </div>
                      </div>
                    </Tooltip>
                  );
                },
                getHeaderProps: () => {
                  return {
                    style: {
                      whiteSpace: 'unset'
                    }
                  };
                },
                headerClassName
              },
              {
                Header: <EnhancedTranslate value='Status' />,
                id: 'status',
                sortable: false,
                Cell: row => {
                  const device = row.original;
                  const lastPairedTime = (
                    <>
                      <span>
                        {null != device.lastPairedTime ? HumanizedTimeElapsedFromEpoch(device.lastPairedTime) : '-'}
                      </span>
                    </>
                  );

                  if (isRvm(device)) return <span>-</span>;
                  return (
                    <Tooltip
                      title={
                        <>
                          <EnhancedTranslate value='Paired' />: {lastPairedTime}
                        </>
                      }
                    >
                      <span className='max-w-full whitespace-normal'>
                        {getDescStatus(device, minBatPctToBeFullyReady)}
                      </span>
                    </Tooltip>
                  );
                },
                minWidth: 120,
                headerClassName
              },
              {
                Header: <EnhancedTranslate value='Version' />,
                id: 'buildDate',
                accessor: device => device.appVersion,
                Cell: row => {
                  if (isRvm(row.original)) return <span>-</span>;
                  const device = row.original;
                  const build = device.deviceShadowReported?.build || '';
                  const translationKeyForDeviceType = getTranslationKeyForDeviceType(getDeviceType(device));
                  const appVersion = device.appVersion;
                  const isOutdated = !isAppVersionUpToDate(device);

                  return (
                    <Tooltip title={build}>
                      <div
                        onClick={() => {
                          navigator.clipboard.writeText(build);
                        }}
                      >
                        {device.deviceVersion && translationKeyForDeviceType ? (
                          <>
                            <EnhancedTranslate value={translationKeyForDeviceType} /> {device.deviceVersion} (
                            {appVersion}) {isOutdated && <WarningEmoji />}
                          </>
                        ) : (
                          <>
                            {appVersion} {isOutdated && <WarningEmoji />}
                          </>
                        )}
                      </div>
                    </Tooltip>
                  );
                },
                width: 220,
                getHeaderProps: () => {
                  return {
                    style: {
                      whiteSpace: 'unset'
                    }
                  };
                },
                style: { whiteSpace: 'unset' },
                headerClassName
              },
              {
                Header: <EnhancedTranslate value='Actions' />,
                id: 'actions',
                width: 450,
                sortable: false,
                Cell: row => <DeviceTableActions device={row.original} />,
                headerClassName
              }
            ]}
            minRows={0}
          />
        </FuseScrollbars>
      </div>
      <DeviceAlarmDialog />
      <RestartAppDialog />
      <RestartDeviceDialog />
      <AddNewDevicesDialog />
      <RemoveDeviceFromObjectDialog />
      <DeviceScreenshotDialog />
      <AddNewDevicesReportDialog />
      <ButtonSettingsDialog />
      <RvmSettingsDialog />
      <AddDevicesFromPackageModal />
      <DownloadLogsDialog />
      <GoogleScriptsWrapper>
        <DeviceLocationDialog />
      </GoogleScriptsWrapper>
    </React.Fragment>
  );
}

export default withRouter(DevicesTable);
