import { Tooltip } from '@material-ui/core';
import { useTypedSelector } from 'app/store/reducers/hooks/useTypedSelector';
import React from 'react';
import { Translate } from 'react-redux-i18n';

import { useTranslatorMode } from '../custom-hooks/useTranslatorMode';

interface EnhancedTranslateProps {
  value: string;
  className?: string;
}

function EnhancedTranslate({ value, className }: EnhancedTranslateProps) {
  const { translatorModeEnabled } = useTypedSelector(({ common }) => common.common);

  const [getTooltipContent] = useTranslatorMode();

  return (
    <span className={className}>
      {translatorModeEnabled && (
        <Tooltip title={getTooltipContent(value)}>
          <span>
            <Translate value={value} />
          </span>
        </Tooltip>
      )}
      {!translatorModeEnabled && <Translate value={value} />}
    </span>
  );
}

export default EnhancedTranslate;
