import { ButtonTheme, ButtonThemeFormProps, ThemeFile } from '../types';

const mapThemeFile = (fontId: number | null, fontName: string | null): ThemeFile => ({
  id: fontId || fontName,
  name: fontName
});

export const mapButtonThemeToFormProps = (buttonTheme: ButtonTheme): ButtonThemeFormProps => {
  return {
    name: buttonTheme.name,
    brand_ids: [],
    header: {
      logo: { id: buttonTheme.logo, name: null },
      background_color: buttonTheme.header_background_color,
      font: mapThemeFile(buttonTheme.header_font_id, buttonTheme.header_font_name),
      text_color: buttonTheme.header_text_color,
      text_size: buttonTheme.header_text_size,
      text_is_uppercase: buttonTheme.header_text_is_uppercase
    },
    center: {
      background_color: buttonTheme.center_background_color,
      font: mapThemeFile(buttonTheme.center_font_id, buttonTheme.center_font_name),
      text_color: buttonTheme.center_text_color,
      text_warning_color: buttonTheme.center_text_warning_color
    },
    bottom: {
      background_color: buttonTheme.bottom_background_color,
      font: mapThemeFile(buttonTheme.bottom_font_id, buttonTheme.bottom_font_name),
      text_color: buttonTheme.bottom_text_color,
      button_color: buttonTheme.bottom_button_color,
      button_text_color: buttonTheme.bottom_button_text_color
    },
    interactionsAndIcons: {
      icons_advisor_color: buttonTheme.center_icons_advisor_color,
      icons_dots_color: buttonTheme.center_icons_dots_color,
      icons_no_connection_color: buttonTheme.center_icons_no_connection_color,
      icons_no_found_color: buttonTheme.center_icons_no_found_color,
      main_button_background_color: buttonTheme.center_main_button_background_color,
      main_button_icon_color: buttonTheme.center_main_button_icon_color
    }
  };
};
