import { DeviceType } from 'types';

const deviceTranslations: Record<DeviceType, string> = {
  [DeviceType.WATCH]: 'device_name_watch',
  [DeviceType.WEBCHAT]: 'device_name_webchat',
  [DeviceType.TABLET]: 'device_name_tablet',
  [DeviceType.BUTTON]: 'device_name_button',
  [DeviceType.RVM]: 'device_name_rvm'
};

export const getTranslationKeyForDeviceType = (deviceType: DeviceType): string | null => {
  return deviceTranslations[deviceType] || null;
};
