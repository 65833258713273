import { useRef } from 'react';
import { I18n } from 'react-redux-i18n';
import validator from 'validator';

interface UseEmailValidationReturn {
  emailErrorMessage: string | undefined;
  isEmailValid: boolean;
  validateEmail: (email: string, updateState?: boolean) => boolean;
}

interface EmailValidationStatus {
  isInvalidFormat: boolean;
  isTooLong: boolean;
}

const MAXIMUM_EMAIL_LENGTH = 250;

export const useEmailValidation = (): UseEmailValidationReturn => {
  const emailValidationStatusRef = useRef<EmailValidationStatus>({
    isInvalidFormat: false,
    isTooLong: false
  });

  const isValidationStatusValid = (emailStatus: EmailValidationStatus) => {
    return Object.values(emailStatus).every(status => !status);
  };

  const validateEmail = (email: string) => {
    const newStatus: EmailValidationStatus = {
      isInvalidFormat: !validator.isEmail(email, {
        ignore_max_length: true
      }),
      isTooLong: email.length > MAXIMUM_EMAIL_LENGTH
    };

    emailValidationStatusRef.current = newStatus;
    return isValidationStatusValid(newStatus);
  };

  const emailErrorMessage = () => {
    const { isInvalidFormat, isTooLong } = emailValidationStatusRef.current;

    if (isInvalidFormat && isTooLong) {
      return I18n.t('invalid_email_max_length_reached');
    }
    if (isTooLong) {
      return I18n.t('Maximum length reached');
    }
    if (isInvalidFormat) {
      return I18n.t('INVALID_EMAIL');
    }
  };

  return {
    emailErrorMessage: emailErrorMessage(),
    isEmailValid: isValidationStatusValid(emailValidationStatusRef.current),
    validateEmail
  };
};
