import axios from 'axios';
import { I18n, loadTranslations, setLocale } from 'react-redux-i18n';

import { isFacilityCreatedAndCached } from '../../../../lib/commonFunctions';
import { LOCALSTORAGE_PACKAGES_LAST_USED_OBID } from '../../../../lib/constants';
import { getCurrentSelectedObid } from '../../../../lib/getObid';
import { getDevices } from '../../../main/apps/devices/store/actions';
import * as PartnerActions from '../../../main/apps/partner/store/actions/partner.actions';
import { pollFacilityStatus } from '../../../main/apps/partner/store/actions/partner.actions';
import multidashboardService from '../../../services/multidashboardService';
import * as MessageActions from '../index';

export const SET_OBID = '[COMMON] SET OBID';
export const SET_ERROR_TRUE = '[COMMON] SET ERROR TRUE';
export const SET_ERROR_FALSE = '[COMMON] SET ERROR FALSE';
export const SET_OBJECTS = '[COMMON] SET OBJECTS';
export const SET_TRANSLATOR_MODE_ON = '[COMMON] SET TRANSLATOR MODE ON';
export const SET_TRANSLATOR_MODE_OFF = '[COMMON] SET TRANSLATOR MODE OFF';
export const SET_SELECTED_LANGUAGES_TO_DISPLAY_IN_TRANSLATOR_MODE =
  '[COMMON] SET SELECTED LANGUAGES TO DISPLAY IN TRANSLATOR MODE';
export const SET_PACKAGE_ID = '[COMMON] SET PACKAGE ID';
export const SET_SERIAL_TO_ADD = '[COMMON] SET SERIAL TO ADD';
export const GET_PACKAGE_BY_ID = '[COMMON] GET PACKAGE BY ID';
export const GET_SERIAL_WITH_ASSIGNMENTS = '[COMMON] GET SERIAL WITH ASSIGNMENTS';
export const ASSIGN_PACKAGE_TO_OBJECT = '[COMMON] ASSIGN PACKAGE TO OBJECT';
export const SET_OBJECT_NAME = '[COMMON] SET OBJECT NAME';
export const SET_IS_API_DOWN = '[COMMON] SET IS API DOWN';
export const SET_GROUP_DEFINES = 'SET_GROUP_DEFINES';
export const SET_GROUP_DEFINES_LOADING = 'SET_GROUP_DEFINES_LOADING';
export const SET_GROUP_DEFINES_ERROR = 'SET_GROUP_DEFINES_ERROR';

export function setObid(obid, multidashboardPath, saveInBackend = true, onSuccess) {
  let request = null;

  if (saveInBackend) {
    request = axios.post('/api/objects/setDashboardUserLastSelectedObid', {
      obid
    });
  }

  return dispatch => {
    let redirected;
    if (request)
      request.then(() => {
        redirected = multidashboardService.handleChange(multidashboardPath);
        if (!redirected) {
          dispatch({
            type: SET_OBID,
            payload: obid
          });
        }
        if (onSuccess) onSuccess();
      });
    else {
      multidashboardService.handleChange(multidashboardPath);
      dispatch({
        type: SET_OBID,
        payload: obid
      });
    }
  };
}

export function setObjectName(objectName) {
  return {
    type: SET_OBJECT_NAME,
    payload: objectName
  };
}

export function setIsApiDown(isApiDown) {
  return {
    type: SET_IS_API_DOWN,
    payload: isApiDown
  };
}

export function setObjects(userId) {
  const request = axios.post('/api/objects/getObjectsForDashboardUser', {
    userId
  });

  return dispatch =>
    request.then(response => {
      for (const facility of response.data) {
        if (!isFacilityCreatedAndCached(facility['status'])) {
          dispatch(pollFacilityStatus(facility['id']));
        }
      }

      return dispatch({
        type: SET_OBJECTS,
        payload: response.data
      });
    });
}

export function setPackageId(packageId) {
  return {
    type: SET_PACKAGE_ID,
    payload: packageId
  };
}

export function setSerialToAdd(serialId) {
  return {
    type: SET_SERIAL_TO_ADD,
    payload: serialId
  };
}

export function getPackageById(packageId) {
  return dispatch =>
    axios
      .get('/api/packages/getById', {
        params: {
          package_id: packageId
        }
      })
      .then(response =>
        dispatch({
          type: GET_PACKAGE_BY_ID,
          payload: response.data
        })
      );
}

export function setTranslationsForLanguage(language) {
  const axiosInstance = axios.create();

  if (axiosInstance.interceptors && axiosInstance.interceptors.request && axiosInstance.interceptors.request.handlers) {
    axiosInstance.interceptors.request.handlers = [];
    delete axiosInstance.defaults.headers.common['Authorization'];
  }

  return dispatch =>
    axiosInstance.get(`${process.env.REACT_APP_CLOUDFRONT_URL}/translations/${language}.json`).then(response => {
      dispatch(
        loadTranslations({
          [language]: response.data
        })
      );
      dispatch(setLocale(language));
    });
}

export function getSerialToAdd(serial) {
  return dispatch =>
    axios
      .get('/api/serials/getSerialWithAssignment', {
        params: {
          serial: serial
        }
      })
      .then(response =>
        dispatch({
          type: GET_SERIAL_WITH_ASSIGNMENTS,
          payload: response.data
        })
      );
}

export function assignPackageToObject(packageId, objectId, isPartner) {
  return dispatch => {
    axios
      .post('/api/packages/assignToObject', {
        packageId,
        objectId
      })
      .then(() => {
        if (isPartner) dispatch(PartnerActions.getEntities());
        else {
          if (String(objectId) === getCurrentSelectedObid()) dispatch(getDevices());
        }
      })
      .then(() => {
        dispatch(
          MessageActions.showMessage({
            message: I18n.t('Devices have been added successfully')
          })
        );
      });
    localStorage.setItem(LOCALSTORAGE_PACKAGES_LAST_USED_OBID, objectId);

    dispatch({
      type: ASSIGN_PACKAGE_TO_OBJECT
    });
  };
}

export function assignSerialToObject(serial, newObid, oldObid, isPartner) {
  return dispatch => {
    axios
      .post('/api/serials/assignSerial', {
        serial: serial,
        newObid: newObid,
        oldObid: oldObid,
        selectedObid: getCurrentSelectedObid()
      })
      .then(() => {
        if (isPartner) dispatch(PartnerActions.getEntities());
        else {
          if (String(newObid) === getCurrentSelectedObid()) dispatch(getDevices());
        }
      })
      .then(() => {
        dispatch(
          MessageActions.showMessage({
            message: I18n.t('Device have been added successfully'),
            autoHideDuration: 3000,
            variant: 'success'
          })
        );
      });
    localStorage.setItem(LOCALSTORAGE_PACKAGES_LAST_USED_OBID, newObid);

    dispatch({
      type: ASSIGN_PACKAGE_TO_OBJECT
    });
  };
}

export function setTranslatorModeOn() {
  localStorage.setItem('translatorModeEnabled', '1');

  const request = axios.get('/api/getTranslationsFromSimpleLocalize');

  return dispatch =>
    request.then(response =>
      dispatch({
        type: SET_TRANSLATOR_MODE_ON,
        payload: response.data
      })
    );
}

export function setTranslatorModeOff() {
  localStorage.setItem('translatorModeEnabled', '0');
  return {
    type: SET_TRANSLATOR_MODE_OFF
  };
}

export function setSelectedLanguagesToDisplayInTranslatorMode(selectedLanguagesToDisplayInTranslatorMode) {
  localStorage.setItem(
    'selectedLanguagesToDisplayInTranslatorMode',
    JSON.stringify(selectedLanguagesToDisplayInTranslatorMode)
  );
  return {
    type: SET_SELECTED_LANGUAGES_TO_DISPLAY_IN_TRANSLATOR_MODE,
    payload: selectedLanguagesToDisplayInTranslatorMode
  };
}

export function setErrorTrue() {
  return {
    type: SET_ERROR_TRUE
  };
}

export function setErrorFalse() {
  return {
    type: SET_ERROR_FALSE
  };
}

export function setGroupDefinesForFacility(obid) {
  return dispatch => {
    dispatch({ type: SET_GROUP_DEFINES_LOADING });

    axios
      .get(`/api/facilities/${obid}/group-defines`)
      .then(response => {
        dispatch({
          type: SET_GROUP_DEFINES,
          payload: response.data
        });
      })
      .catch(() => {
        dispatch({
          type: SET_GROUP_DEFINES_ERROR
        });
      });
  };
}
