import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { I18n } from 'react-redux-i18n';
import { AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { FetchingState, UserRole } from 'types';

import { STATUS_INITIAL, UNRECOGNIZED_ERROR } from '../../../../../lib/constants';
import { setObjects, showMessage } from '../../../../store/actions';
import { RESET_STORE } from '../../../../store/actions/reduxActions';
import { ERROR_USER_ALREADY_HAS_PERMISSION, ERROR_USER_ALREADY_INVITED } from '../../dashboardAccess/constants';
import { NEW_BRAND_ID, NONE_BRAND_ID } from '../Dialogs/CreateNewFacility/constants';
import { PARTNER_FACILITIES_TAB_TABLE, PARTNER_TAB_FACILITIES } from '../constants';
import { getEntities } from './actions';

export interface Manager {
  email: string;
  id: number;
  lastLogin: string | null;
  name: string | null;
  phoneNum: string | null;
  role: UserRole;
  surname: string | null;
  is_from_group?: boolean;
}

interface NewFacilityForm {
  address: {
    country: string;
    locality: string;
    street: string;
    streetNumber: string;
    zipCode: string;
  };
  language: string | null;
  suggestedLanguagesLoading: boolean;
  phoneNumber: string | null;
  type: string | null;
  managers: string[];
  facilityCreationStatus: string;
  isAddressEdited: boolean;
  isPhoneNumberEdited: boolean;
  isNameEdited: boolean;
  isExternalIdEdited: boolean;
  isLanguageEdited: boolean;
  isAddressIncomplete: boolean | null;
  brandName: string | null;
  brandId: number;
  selectedBrandId: number;
  isBrandEditing: boolean;
  storeType: string | null;
  customGooglePlace?: string;
  suggestionsFetchingState: FetchingState;
}

interface InitialState {
  selectedTab: string;
  selectedFacilitiesTab: string;
  createFacilityDialogOpen: boolean;
  disableFacilityDialog: {
    open: boolean;
    facilityId: number | null;
    facilityName: string;
    hasDevicesAssigned: boolean | null;
    hasDevicesAssignedLoading: boolean;
  };
  newFacilityForm: NewFacilityForm;
  managersDialog: {
    open: boolean;
    facilityId: number | null;
    managersLoading: boolean;
    managers: Manager[];
    isGroup: boolean;
  };
  editSupervisorDialog: boolean;
}

export const initialState: InitialState = {
  selectedTab: PARTNER_TAB_FACILITIES,
  selectedFacilitiesTab: PARTNER_FACILITIES_TAB_TABLE,
  createFacilityDialogOpen: false,
  disableFacilityDialog: {
    open: false,
    facilityId: null,
    facilityName: '',
    hasDevicesAssigned: null,
    hasDevicesAssignedLoading: false
  },
  newFacilityForm: {
    address: {
      country: '',
      locality: '',
      street: '',
      streetNumber: '',
      zipCode: ''
    },
    language: null,
    suggestedLanguagesLoading: false,
    phoneNumber: null,
    type: null,
    managers: [''],
    facilityCreationStatus: STATUS_INITIAL,
    isAddressEdited: false,
    isPhoneNumberEdited: false,
    isNameEdited: false,
    isExternalIdEdited: false,
    isLanguageEdited: false,
    isAddressIncomplete: null,
    customGooglePlace: undefined,
    brandName: null,
    brandId: NONE_BRAND_ID,
    isBrandEditing: false,
    storeType: null,
    suggestionsFetchingState: FetchingState.INITIAL,
    selectedBrandId: NONE_BRAND_ID
  },
  managersDialog: {
    open: false,
    facilityId: null,
    managersLoading: false,
    managers: [],
    isGroup: false
  },
  editSupervisorDialog: false
};

export const fetchSuggestedLanguagesForCountry = (country: string, zipCode: string) => async (dispatch: Dispatch) => {
  dispatch(
    setInNewFacilityForm({
      key: 'suggestedLanguagesLoading',
      value: true
    })
  );
  axios
    .get(`/api/suggested-language`, {
      params: {
        country: country,
        zipCode: zipCode
      }
    })
    .then(response => {
      dispatch(
        setInNewFacilityForm({
          key: 'language',
          value: response.data.mainLanguage
        })
      );
    })
    .catch(err => {
      if (err.response.status === 400) {
        // do nothing
      }
    })
    .finally(() => {
      dispatch(
        setInNewFacilityForm({
          key: 'suggestedLanguagesLoading',
          value: false
        })
      );
    });
};

export const fetchHasDevicesAssigned = (facilityId: string) => async (dispatch: Dispatch) => {
  dispatch(setHasFacilitiesAssignedLoading(true));
  axios
    .get(`/api/facilities/${facilityId}/has-devices-assigned`)
    .then(response => {
      dispatch(setHasFacilitiesAssigned(response.data));
    })
    .finally(() => {
      dispatch(setHasFacilitiesAssignedLoading(false));
    });
};

export const disableFacility =
  (facilityId: string, userId: string) =>
  async (dispatch: ThunkDispatch<Record<string, unknown>, unknown, AnyAction>) => {
    axios.post(`/api/facilities/${facilityId}/disable`).then(() => {
      dispatch(getEntities());
      dispatch(setObjects(userId));
    });
  };

export const fetchFacilityManagers =
  (facilityId: number, loading = true) =>
  async (dispatch: Dispatch) => {
    if (loading) dispatch(setManagersLoading(true));
    axios
      .get(`/api/facilities/${facilityId}/dashboard-users`)
      .then(response => {
        dispatch(setManagers(response.data));
      })
      .finally(() => {
        dispatch(setManagersLoading(false));
      });
  };

export const fetchGroupManagers =
  (groupId: number, loading = true) =>
  async (dispatch: Dispatch) => {
    if (loading) dispatch(setManagersLoading(true));
    axios
      .get(`/api/facilities-groups/${groupId}/managers`)
      .then(response => {
        dispatch(setManagers(response.data));
      })
      .finally(() => {
        dispatch(setManagersLoading(false));
      });
  };

export function addGroupManager({ email, id, role }: { email: string; role: string; id: number }) {
  return (dispatch: Dispatch) => {
    axios
      .post(`/api/facilities-groups/${id}/managers`, {
        email,
        role
      })
      .then(response => {
        dispatch(
          showMessage({
            message: I18n.t('Invitation sent'),
            variant: 'success',
            autoHideDuration: 3000
          })
        );
        dispatch(
          addManagerToList({
            ...response.data,
            email,
            role
          })
        );
      })
      .catch(error => {
        if ([ERROR_USER_ALREADY_HAS_PERMISSION, ERROR_USER_ALREADY_INVITED].includes(error?.response?.data?.error))
          dispatch(
            showMessage({
              message: I18n.t(error.response.data.error),
              variant: 'warning',
              autoHideDuration: 3000
            })
          );
        else
          dispatch(
            showMessage({
              message: I18n.t(UNRECOGNIZED_ERROR),
              variant: 'error',
              autoHideDuration: 3000
            })
          );
      });
  };
}

export const fetchFacilitySuggestions = createAsyncThunk(
  'facilities/fetchSuggestions',
  async (facilityName: string, { dispatch }) => {
    if (!facilityName) return;
    try {
      const { data } = await axios.post<{
        store_type: string | null;
        brand_name: string | null;
        brand_id: number | null;
      }>('/api/facilities/suggestions', {
        name: facilityName
      });
      return data;
    } catch (error) {
      dispatch(
        showMessage({
          variant: 'warning',
          message: 'Failed to auto-fill fields'
        })
      );
    }
  }
);

const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    selectTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    openManagersDialog: (state, action) => {
      state.managersDialog = {
        ...initialState.managersDialog,
        open: true,
        isGroup: Boolean(action.payload.isGroup),
        facilityId: action.payload.id
      };
    },
    closeManagersDialog: state => {
      state.managersDialog = initialState.managersDialog;
    },
    openCreateFacilityDialog: (state, action) => {
      state.newFacilityForm.type = action.payload;
      state.createFacilityDialogOpen = true;
    },
    closeCreateFacilityDialog: state => {
      state.createFacilityDialogOpen = false;
      state.newFacilityForm = initialState.newFacilityForm;
    },
    setInNewFacilityForm: (
      state,
      action: PayloadAction<{ key: keyof NewFacilityForm; value: NewFacilityForm[keyof NewFacilityForm] }>
    ) => {
      const { key, value } = action.payload;
      state.newFacilityForm = {
        ...state.newFacilityForm,
        [key]: value
      };
    },
    setManagersLoading: (state, action) => {
      state.managersDialog.managersLoading = action.payload;
    },

    setManagers: (state, action) => {
      state.managersDialog.managers = action.payload;
    },
    addManagerToList: (state, action) => {
      state.managersDialog.managers.push(action.payload);
    },
    removeManagerFromList: (state, action) => {
      const index = state.managersDialog.managers.findIndex(manager => manager.id === action.payload);
      if (index >= 0) {
        state.managersDialog.managers.splice(index, 1);
      }
    },
    setManagerInNewFacilityForm: (state, action) => {
      const { index, value } = action.payload;
      if (index >= 0 && index < state.newFacilityForm.managers.length) {
        state.newFacilityForm.managers[index] = value;
      }
    },
    resetNewFacilityForm: state => {
      state.newFacilityForm = {
        ...initialState.newFacilityForm,
        type: state.newFacilityForm.type
      };
    },
    selectFacilitiesTab: (state, action) => {
      state.selectedFacilitiesTab = action.payload;
    },
    setFacilityCreationStatus: (state, action) => {
      state.newFacilityForm.facilityCreationStatus = action.payload;
    },
    setDisableFacilityDialog: (state, action) => {
      state.disableFacilityDialog = action.payload;
    },
    setHasFacilitiesAssigned: (state, action) => {
      state.disableFacilityDialog.hasDevicesAssigned = action.payload;
    },
    setHasFacilitiesAssignedLoading: (state, action) => {
      state.disableFacilityDialog.hasDevicesAssignedLoading = action.payload;
    },
    openEditSupervisorDialog: state => {
      state.editSupervisorDialog = true;
    },
    closeEditSupervisorDialog: state => {
      state.editSupervisorDialog = false;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(RESET_STORE, () => {
        return { ...initialState };
      })
      .addCase(fetchFacilitySuggestions.pending, state => {
        state.newFacilityForm.suggestionsFetchingState = FetchingState.LOADING;
      })
      .addCase(fetchFacilitySuggestions.fulfilled, (state, action) => {
        state.newFacilityForm.suggestionsFetchingState = FetchingState.SUCCEEDED;
        if (action.payload) {
          const { brand_id, brand_name, store_type } = action.payload;
          state.newFacilityForm.brandName = brand_name;
          let brandId: number;

          if (brand_id === null) {
            brandId = brand_name ? NEW_BRAND_ID : NONE_BRAND_ID;
          } else {
            brandId = brand_id;
          }
          state.newFacilityForm.brandId = brandId;
          state.newFacilityForm.selectedBrandId = brandId;
          state.newFacilityForm.storeType = store_type;
        }
      })
      .addCase(fetchFacilitySuggestions.rejected, state => {
        state.newFacilityForm.suggestionsFetchingState = FetchingState.FAILED;
      });
  }
});
export const {
  selectTab,
  selectFacilitiesTab,
  setInNewFacilityForm,
  setFacilityCreationStatus,
  openCreateFacilityDialog,
  closeCreateFacilityDialog,
  resetNewFacilityForm,
  setDisableFacilityDialog,
  setHasFacilitiesAssigned,
  setHasFacilitiesAssignedLoading,
  setManagerInNewFacilityForm,
  openManagersDialog,
  closeManagersDialog,
  setManagersLoading,
  setManagers,
  closeEditSupervisorDialog,
  openEditSupervisorDialog,
  addManagerToList,
  removeManagerFromList
} = partnerSlice.actions;
export default partnerSlice.reducer;
