import {
  Button,
  CardContent,
  Checkbox,
  CircularProgress,
  Fade,
  FormControlLabel,
  Paper,
  Typography
} from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import fullCwatchLogo from '../../../assets/fullCwatchLogo.jpg';
import { getCountry } from '../../../lib/languages';
import {
  completeRegistration,
  fetchInvitationLinkDetails,
  setInCompleteRegistrationForm,
  setInformationAboutTermsOfServiceDialogOpen
} from '../../auth/store/LoginSlice';
import EnhancedTranslate from '../../common-components/EnhancedTranslate';
import PhoneInputWithFlagsAndCountryCodes from '../../common-components/PhoneInputWithFlagsAndCountryCodes';
import LanguagesSelect from '../../common-components/Selects/LanguagesSelect';
import PasswordTextField from '../../common-components/TextFields/PasswordTextField';
import UserNameTextField from '../../common-components/TextFields/UserNameTextField';
import useResize from '../../custom-hooks/useResize';
import PasswordsValidationRules from '../../fuse-layouts/shared-components/PasswordsValidationRules';
import GoBackToLoginButton from '../GoBackToLoginButton';
import LoginFooter from '../LoginFooter';
import { VIEW_COMPLETE_REGISTRATION_NEW_USER, VIEW_COMPLETE_REGISTRATION_OLD_USER } from '../constants';
import InformationAboutTermsOfServiceDialog from '../dialogs/InformationAboutTermsOfServiceDialog';

const useStyles = makeStyles(() => ({
  logoContainer: {
    position: 'relative',
    display: 'inline-block',
    alignSelf: 'center'
  },
  logoTextOverlay: {
    position: 'absolute',
    top: '-5px',
    left: '145px',
    whiteSpace: 'nowrap'
  }
}));

export default function CompleteRegistrationForm() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    completeRegistrationForm,
    invitationLinkDetailsError,
    invitationLinkDetailsLoading,
    selectedView,
    token,
    invitationLinkDetails
  } = useSelector(state => state.login);
  const i18n = useSelector(({ i18n }) => i18n);

  const { name, surname, phoneNum, password, passwordConfirmation, marketingAgreement, language, email } =
    completeRegistrationForm;

  const [passwordRulesPopperAnchor, setPasswordRulesPopperAnchor] = useState(null);
  const isPasswordRulesPopperOpen = useResize(1200);

  const [arePasswordsValid, setArePasswordsValid] = useState(false);

  useEffect(() => {
    if (selectedView === VIEW_COMPLETE_REGISTRATION_NEW_USER) {
      dispatch(fetchInvitationLinkDetails(token));
    } else dispatch(setInformationAboutTermsOfServiceDialogOpen(true));
  }, [dispatch, selectedView, token]);

  useEffect(() => {
    if (invitationLinkDetails.language)
      dispatch(
        setInCompleteRegistrationForm({
          key: 'language',
          value: invitationLinkDetails.language
        })
      );
    if (invitationLinkDetails.email)
      dispatch(
        setInCompleteRegistrationForm({
          key: 'email',
          value: invitationLinkDetails.email
        })
      );
  }, [dispatch, invitationLinkDetails]);

  function canBeSubmitted() {
    if (selectedView === VIEW_COMPLETE_REGISTRATION_OLD_USER)
      return !!name && !!surname && !!phoneNum && phoneNum.length > 7 && marketingAgreement && !!language;
    // && termsOfServiceAccepted TODO UNCOMMENT WHEN CWAT-3268 IS UNBLOCKED
    else
      return (
        !!name &&
        !!surname &&
        !!phoneNum &&
        marketingAgreement &&
        phoneNum.length > 7 &&
        !!password &&
        !!passwordConfirmation &&
        arePasswordsValid &&
        !!language
      );
    // && termsOfServiceAccepted TODO UNCOMMENT WHEN CWAT-3268 IS UNBLOCKED
  }

  function submit() {
    let payload = {
      name,
      surname,
      phoneNum,
      password,
      language,
      email,
      token
    };
    if (selectedView === VIEW_COMPLETE_REGISTRATION_NEW_USER) {
      payload['passwordConfirmation'] = passwordConfirmation;
    }
    dispatch(completeRegistration(payload));
  }

  return (
    <>
      <CardContent className='flex h-full flex-col p-32'>
        <GoBackToLoginButton />
        <div className={classes.logoContainer}>
          {selectedView === VIEW_COMPLETE_REGISTRATION_NEW_USER && (
            <div className={classes.logoTextOverlay}>
              <Typography className='text-20'>
                <EnhancedTranslate value='Welcome to' />
              </Typography>
            </div>
          )}
          <img className='w-256' src={fullCwatchLogo} alt='logo' />
        </div>

        {invitationLinkDetailsLoading ? (
          <div className='mt-32 flex justify-center'>
            <CircularProgress color='secondary' />
          </div>
        ) : (
          <>
            {invitationLinkDetailsError !== null ? (
              <div className='mt-32'>
                <Alert severity='error'>
                  <EnhancedTranslate value={invitationLinkDetailsError} />
                </Alert>
              </div>
            ) : (
              <>
                <div className='mt-16 text-center'>
                  <Typography className='font-bold' color='textSecondary'>
                    <EnhancedTranslate value='complete_registration_form_header' />
                  </Typography>
                </div>
                <div className='overflow-auto p-4'>
                  <UserNameTextField
                    className='mt-16'
                    size='small'
                    label='First name'
                    autoComplete='first-name'
                    value={name}
                    onChange={newValue => {
                      dispatch(
                        setInCompleteRegistrationForm({
                          key: 'name',
                          value: newValue
                        })
                      );
                    }}
                  />
                  <UserNameTextField
                    className='mt-12'
                    size='small'
                    label='Surname'
                    autoComplete='surname'
                    value={surname}
                    onChange={newValue => {
                      dispatch(
                        setInCompleteRegistrationForm({
                          key: 'surname',
                          value: newValue
                        })
                      );
                    }}
                  />

                  {selectedView === VIEW_COMPLETE_REGISTRATION_NEW_USER && (
                    <>
                      {!isPasswordRulesPopperOpen && (
                        <div className='mt-16'>
                          <PasswordsValidationRules
                            password={password}
                            passwordConfirmation={passwordConfirmation}
                            setArePasswordsValid={setArePasswordsValid}
                          />
                        </div>
                      )}

                      <PasswordTextField
                        className='mt-12'
                        size='small'
                        label='Password'
                        value={password}
                        autoComplete='new-password'
                        onChange={newValue => {
                          dispatch(
                            setInCompleteRegistrationForm({
                              key: 'password',
                              value: newValue
                            })
                          );
                        }}
                      />

                      <div
                        ref={node => {
                          setPasswordRulesPopperAnchor(node);
                        }}
                      />

                      <PasswordTextField
                        className='mt-12'
                        size='small'
                        label='Repeat password'
                        value={passwordConfirmation}
                        onChange={newValue => {
                          dispatch(
                            setInCompleteRegistrationForm({
                              key: 'passwordConfirmation',
                              value: newValue
                            })
                          );
                        }}
                      />

                      <Popper
                        open={isPasswordRulesPopperOpen}
                        placement='right'
                        disablePortal={false}
                        anchorEl={passwordRulesPopperAnchor}
                        transition
                        modifiers={{
                          flip: {
                            enabled: false
                          },
                          offset: {
                            enabled: true,
                            offset: '20, 100'
                          }
                        }}
                      >
                        {({ TransitionProps }) => (
                          <Fade {...TransitionProps} timeout={350}>
                            <Paper>
                              <div className='p-16'>
                                <PasswordsValidationRules
                                  password={password}
                                  passwordConfirmation={passwordConfirmation}
                                  setArePasswordsValid={setArePasswordsValid}
                                />
                              </div>
                            </Paper>
                          </Fade>
                        )}
                      </Popper>
                    </>
                  )}

                  <LanguagesSelect
                    className='mt-12'
                    size='small'
                    value={language}
                    onChange={event => {
                      dispatch(
                        setInCompleteRegistrationForm({
                          key: 'language',
                          value: event.target.value
                        })
                      );
                    }}
                  />

                  <PhoneInputWithFlagsAndCountryCodes
                    className='mt-12'
                    size='small'
                    specialLabel={<EnhancedTranslate value='Phone' />}
                    country={getCountry(i18n.locale)}
                    inputProps={{
                      style: {
                        width: '100%'
                      }
                    }}
                    value={phoneNum}
                    onChange={(value, country, e, formattedValue) => {
                      dispatch(
                        setInCompleteRegistrationForm({
                          key: 'phoneNum',
                          value: formattedValue
                        })
                      );
                    }}
                  />

                  {/* TODO UNCOMMENT WHEN CWAT-3268 IS UNBLOCKED */}
                  {/*<div className="flex mt-16">*/}
                  {/*    <FormControlLabel*/}
                  {/*        label={*/}
                  {/*            <div>*/}
                  {/*                <EnhancedTranslate value={"I accept"}/>*/}
                  {/*                &nbsp;*/}
                  {/*                <LinkText*/}
                  {/*                    onClick={goToTermsOfUse}*/}
                  {/*                >*/}
                  {/*                    <EnhancedTranslate value={"Terms of use"}/>*/}
                  {/*                </LinkText>*/}
                  {/*            </div>*/}
                  {/*        }*/}
                  {/*        control={*/}
                  {/*            <Checkbox*/}
                  {/*                onClick={(event) => {*/}
                  {/*                    event.stopPropagation();*/}
                  {/*                    dispatch(setInCompleteRegistrationForm({*/}
                  {/*                        key: "termsOfServiceAccepted",*/}
                  {/*                        value: event.target.checked*/}
                  {/*                    }))*/}
                  {/*                }}*/}
                  {/*                checked={termsOfServiceAccepted}*/}
                  {/*            />*/}
                  {/*        }/>*/}
                  {/*</div>*/}

                  <div className='mt-12 flex'>
                    <FormControlLabel
                      label={<EnhancedTranslate value='marketing_agreement_info' />}
                      control={
                        <Checkbox
                          onClick={event => {
                            event.stopPropagation();
                            dispatch(
                              setInCompleteRegistrationForm({
                                key: 'marketingAgreement',
                                value: event.target.checked
                              })
                            );
                          }}
                          checked={marketingAgreement}
                        />
                      }
                    />
                  </div>
                </div>

                <Button
                  variant='contained'
                  color='secondary'
                  disabled={!canBeSubmitted()}
                  onClick={submit}
                  className='mx-auto mt-16 w-full'
                >
                  <EnhancedTranslate value='Create account' />
                </Button>
              </>
            )}
          </>
        )}

        <div className='flex-1' />

        <LoginFooter />
      </CardContent>
      <InformationAboutTermsOfServiceDialog />
    </>
  );
}
