import { InputAdornment } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useState } from 'react';

import { MAX_PASSWORD_LENGTH } from '../../../lib/constants';
import EnhancedTextField from '../inputs/EnhancedTextField';

export default function PasswordTextField(props) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <EnhancedTextField
      {...props}
      fullWidth
      type={isPasswordVisible ? 'text' : 'password'}
      variant='outlined'
      inputProps={{
        maxLength: MAX_PASSWORD_LENGTH,
        ...props.inputProps
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position='center'>
            <IconButton onClick={() => setIsPasswordVisible(!isPasswordVisible)} edge='end'>
              {isPasswordVisible ? <Visibility className='text-20' /> : <VisibilityOff className='text-20' />}
            </IconButton>
          </InputAdornment>
        )
      }}
      onChange={event => {
        let newValue = event.target.value;
        if (newValue.length <= MAX_PASSWORD_LENGTH) props.onChange(newValue);
      }}
    />
  );
}
