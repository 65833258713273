import * as Actions from 'app/main/apps/communication/predefinedMessages/store/actions';
import { AnyAction } from 'redux';
import { PredefinedMessageDialogType } from 'types';

import _ from '../../../../../../../@lodash';
import { RESET_STORE } from '../../../../../../store/actions/reduxActions';

interface PredefinedMessage {
  id: number;
  text: string;
  msgCount: number;
  active: number;
  order: number;
  group_message_id: number | null;
  jobTitlesIds: number[];
}

type PredefinedMessagesDictionary = {
  [id: number]: PredefinedMessage;
};

interface InitialState {
  searchText: string;
  entities: PredefinedMessagesDictionary;
  selectedContactIds: number[];
  routeParams: Record<string, unknown>;
  predefinedMessageDialog: {
    type: PredefinedMessageDialogType;
    open: boolean;
    data: PredefinedMessage | null;
  };
  loading: boolean;
  translations: [];
  jobTitlesForPredefinedMessage: [];
}

const initialState: InitialState = {
  searchText: '',
  entities: {},
  selectedContactIds: [],
  routeParams: {},
  predefinedMessageDialog: {
    type: PredefinedMessageDialogType.NEW,
    open: false,
    data: null
  },
  loading: false,
  translations: [],
  jobTitlesForPredefinedMessage: []
};

// eslint-disable-next-line complexity
export const predefinedMessagesReducer = function (state = initialState, action: AnyAction): InitialState {
  switch (action.type) {
    case Actions.SET_LOADING_TRUE: {
      return {
        ...state,
        loading: true
      };
    }
    case Actions.GET_PREDEFINED_MESSAGES: {
      return {
        ...state,
        entities: _.keyBy(action.payload, 'id'),
        routeParams: action.routeParams,
        loading: false
      };
    }
    case Actions.SET_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.searchText
      };
    }
    case Actions.UPDATE_PREDEFINED_MESSAGE: {
      const newEntities = { ...state.entities };

      newEntities[action.sms.id] = action.sms;

      return {
        ...state,
        entities: newEntities
      };
    }
    case Actions.REMOVE_JOB_TITLE: {
      const newEntities = { ...state.entities };
      const jtId = parseInt(action.jobTitleId);
      const index = newEntities[action.predefinedMessageId]['jobTitlesIds'].indexOf(jtId);

      if (index !== -1) newEntities[action.predefinedMessageId]['jobTitlesIds'].splice(index, 1);

      return {
        ...state,
        entities: newEntities
      };
    }
    case Actions.ADD_JOB_TITLE: {
      const newEntities = _.cloneDeep(state.entities);
      newEntities[action.predefinedMessageId]['jobTitlesIds'] = [
        ...newEntities[action.predefinedMessageId]['jobTitlesIds'],
        ...action.jobTitleId
      ];

      return {
        ...state,
        entities: newEntities
      };
    }
    case Actions.TOGGLE_IN_SELECTED_PREDEFINED_MESSAGES: {
      const contactId = action.contactId;

      let selectedContactIds = [...state.selectedContactIds];

      if (selectedContactIds.find(id => id === contactId) !== undefined) {
        selectedContactIds = selectedContactIds.filter(id => id !== contactId);
      } else {
        selectedContactIds = [...selectedContactIds, contactId];
      }

      return {
        ...state,
        selectedContactIds: selectedContactIds
      };
    }
    case Actions.TOGGLE_DISABLED: {
      const newEntities = { ...state.entities };
      newEntities[action.smsId]['active'] = action.state;
      return {
        ...state,
        entities: newEntities
      };
    }
    case Actions.DISABLE_MULTIPLE: {
      const newEntities = { ...state.entities };

      for (const smsId of action.smsIds) {
        newEntities[smsId]['active'] = 0;
      }
      return {
        ...state,
        entities: newEntities
      };
    }
    case Actions.REMOVE_MESSAGES: {
      const newEntities = { ...state.entities };

      for (const smsId of action.messagesids) {
        delete newEntities[smsId];
      }
      return {
        ...state,
        entities: newEntities
      };
    }
    case Actions.ENABLE_MULTIPLE: {
      const newEntities = { ...state.entities };

      for (const smsId of action.smsIds) {
        newEntities[smsId]['active'] = 1;
      }
      return {
        ...state,
        entities: newEntities
      };
    }
    case Actions.SELECT_ALL_PREDEFINED_MESSAGES: {
      const selectedPredefinedMessagesIds = Object.values(state.entities)
        .filter(predefinedMessage => predefinedMessage.group_message_id === null)
        .map(predefinedMessage => predefinedMessage.id);

      return {
        ...state,
        selectedContactIds: selectedPredefinedMessagesIds
      };
    }
    case Actions.DESELECT_ALL_PREDEFINED_MESSAGES: {
      return {
        ...state,
        selectedContactIds: []
      };
    }
    case Actions.REMOVE_PREDEFINED_MESSAGE: {
      const newEntities = { ...state.entities };
      delete newEntities[action.smsId];
      return {
        ...state,
        entities: newEntities
      };
    }
    case Actions.OPEN_NEW_PREDEFINED_MESSAGE_DIALOG: {
      return {
        ...state,
        predefinedMessageDialog: {
          type: PredefinedMessageDialogType.NEW,
          open: true,
          data: null
        }
      };
    }
    case Actions.CLOSE_PREDEFINED_MESSAGE_DIALOG: {
      return {
        ...state,
        predefinedMessageDialog: {
          type: PredefinedMessageDialogType.NEW,
          open: false,
          data: null
        }
      };
    }
    case Actions.OPEN_EDIT_PREDEFINED_MESSAGE_DIALOG: {
      return {
        ...state,
        predefinedMessageDialog: {
          type: PredefinedMessageDialogType.EDIT,
          open: true,
          data: action.data
        }
      };
    }
    case RESET_STORE:
      return initialState;
    default: {
      return state;
    }
  }
};

export default predefinedMessagesReducer;
