/* eslint-disable complexity */
import * as Actions from 'app/store/actions/common';
import { DEFAULT_BRIDGE_ENABLED, DEFAULT_RECOMMENDING_FACILITIES_ENABLED, DEFAULT_RMA_ENABLED } from 'lib/constants';
import { FetchingState } from 'types';

import { RESET_STORE } from '../../actions/reduxActions';

const initialState = {
  obid: null,
  objectName: null,
  groupDefinesFetchingState: FetchingState.INITIAL,
  groupDefines: {
    group_id: null,
    is_bridge_enabled: DEFAULT_BRIDGE_ENABLED,
    is_rma_enabled: DEFAULT_RMA_ENABLED,
    is_recommending_facilities_enabled: DEFAULT_RECOMMENDING_FACILITIES_ENABLED,
    buttonLanguages: '',
    facilities: [],
    minutesUntilHidingMessages: null,
    themeButtonId: null,
    userMessageHidingTimeEnabled: false,
    userPrivacyEnabled: false
  },
  error: false,
  objects: [],
  translatorModeEnabled: false,
  simpleLocalizeTranslations: null,
  packageId: null,
  serialToAdd: null,
  package: null,
  serialWithAssignments: null,
  isApiDown: false,
  selectedLanguagesToDisplayInTranslatorMode: []
};

const common = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_OBID: {
      return {
        ...state,
        obid: action.payload
      };
    }
    case Actions.SET_IS_API_DOWN: {
      return {
        ...state,
        isApiDown: action.payload
      };
    }
    case Actions.SET_OBJECT_NAME: {
      return {
        ...state,
        objectName: action.payload
      };
    }
    case Actions.SET_PACKAGE_ID: {
      return {
        ...state,
        packageId: action.payload
      };
    }
    case Actions.SET_SERIAL_TO_ADD: {
      return {
        ...state,
        serialToAdd: action.payload
      };
    }
    case Actions.GET_PACKAGE_BY_ID: {
      return {
        ...state,
        package: action.payload
      };
    }
    case Actions.GET_SERIAL_WITH_ASSIGNMENTS: {
      return {
        ...state,
        serialWithAssignments: action.payload
      };
    }
    case Actions.SET_ERROR_TRUE: {
      return {
        ...state,
        error: true
      };
    }
    case Actions.SET_SELECTED_LANGUAGES_TO_DISPLAY_IN_TRANSLATOR_MODE: {
      return {
        ...state,
        selectedLanguagesToDisplayInTranslatorMode: action.payload
      };
    }
    case Actions.SET_ERROR_FALSE: {
      return {
        ...state,
        error: false
      };
    }
    case Actions.SET_TRANSLATOR_MODE_ON: {
      return {
        ...state,
        translatorModeEnabled: true,
        simpleLocalizeTranslations: action.payload
      };
    }
    case Actions.SET_TRANSLATOR_MODE_OFF: {
      return {
        ...state,
        translatorModeEnabled: false,
        simpleLocalizeTranslations: null
      };
    }
    case Actions.SET_OBJECTS: {
      return {
        ...state,
        objects: action.payload
      };
    }
    case Actions.SET_GROUP_DEFINES_LOADING:
      return {
        ...state,
        groupDefinesFetchingState: FetchingState.LOADING
      };

    case Actions.SET_GROUP_DEFINES:
      return {
        ...state,
        groupDefines: Object.keys(action.payload).length ? action.payload : initialState.groupDefines,
        groupDefinesFetchingState: FetchingState.SUCCEEDED
      };

    case Actions.SET_GROUP_DEFINES_ERROR:
      return {
        ...state,
        groupDefinesFetchingState: FetchingState.FAILED
      };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default common;
