import history from '@history';
import * as Sentry from '@sentry/react';
import jwtService from 'app/services/jwtService';
import {
  setObid,
  setObjects,
  setSelectedLanguagesToDisplayInTranslatorMode,
  setTranslatorModeOn
} from 'app/store/actions/common';
import axios from 'axios';

import { getCurrentSelectedObid } from '../../../../lib/getObid';
import { getSettings } from '../../../main/apps/settings/store/actions';
import { RESET_STORE } from '../../../store/actions/reduxActions';

export const SET_USER_DATA = '[USER] SET DATA';
export const GET_USER_ROLE = '[USER] GET USER ROLE';
export const UPDATE_CURRENT_ROLE = '[USER] UPDATE CURRENT ROLE';
export const SET_PACKAGE_ID = '[USER] SET PACKAGE ID';

export function setUserData(user) {
  return dispatch => {
    let obid = getCurrentSelectedObid();
    if (!obid) {
      obid = user.lastUsedFacility.id;
    }
    dispatch(setObid(obid, user.lastUsedFacility.multidashboardPath, false));
    dispatch(getSettings(obid));
    Sentry.setTag('obid', obid);

    /* Set User Settings */
    if (localStorage.getItem('translatorModeEnabled') === '1') {
      dispatch(setTranslatorModeOn());
      if (localStorage.getItem('selectedLanguagesToDisplayInTranslatorMode'))
        dispatch(
          setSelectedLanguagesToDisplayInTranslatorMode(
            JSON.parse(localStorage.getItem('selectedLanguagesToDisplayInTranslatorMode'))
          )
        );
    }
    dispatch(setObjects(user.userId));
    dispatch(getDashboardUserRole(user.userId, obid));

    return dispatch({
      type: SET_USER_DATA,
      payload: user
    });
  };
}

export function getDashboardUserRole(userId, obid) {
  const request = axios.get('/api/dashboardUsers/getRoleForUser', {
    params: {
      selectedObid: getCurrentSelectedObid(),
      userId: userId
    }
  });
  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_USER_ROLE,
        roles: response.data,
        obid: obid
      });
    });
}

export function logoutUser(redirectToLoginPage = true) {
  return dispatch => {
    jwtService.logout();

    dispatch({
      type: RESET_STORE
    });

    Sentry.setUser(null);

    localStorage.removeItem('jwt_access_token_object');

    if (redirectToLoginPage) history.push({ pathname: '/login' });
  };
}

export function updateCurrentRole(obid) {
  return {
    type: UPDATE_CURRENT_ROLE,
    obid: obid
  };
}
