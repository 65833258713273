import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { Dispatch } from 'redux';
import { Obid, ObjectDetails } from 'types';

import _ from '../../../../@lodash';
import { RESET_STORE } from '../../actions/reduxActions';

interface ObjectAmountOfDevices {
  [key: string]: {
    type: string;
    amount: number;
  };
}

interface InitialState {
  objectDetails: ObjectDetails | null;
  objectAmountOfDevices: ObjectAmountOfDevices;
  objectAmountOfDevicesLoading: boolean;
}

export const initialState: InitialState = {
  objectDetails: null,
  objectAmountOfDevices: {},
  objectAmountOfDevicesLoading: false
};

export const fetchObjectDetails = (obid: Obid) => async (dispatch: Dispatch) => {
  axios.get(`/api/objects/${obid}`).then(res => {
    dispatch(setObjectDetails(res.data));
  });
};

export const fetchObjectAmountOfDevices = (obid: Obid) => async (dispatch: Dispatch) => {
  dispatch(setObjectAmountOfDevicesLoading(true));
  axios.get(`/api/objects/${obid}/get-amount-of-devices`).then(res => {
    dispatch(setObjectAmountOfDevices(res.data));
    dispatch(setObjectAmountOfDevicesLoading(false));
  });
};

const objectsSlice = createSlice({
  name: 'objects',
  initialState,
  reducers: {
    setObjectDetails: (state, action) => {
      state.objectDetails = action.payload;
    },
    setObjectAmountOfDevices: (state, action) => {
      state.objectAmountOfDevices = _.keyBy(action.payload, 'type');
    },
    setObjectAmountOfDevicesLoading: (state, action) => {
      state.objectAmountOfDevicesLoading = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(RESET_STORE, () => {
      return { ...initialState };
    });
  }
});

export const { setObjectDetails, setObjectAmountOfDevices, setObjectAmountOfDevicesLoading } = objectsSlice.actions;

export default objectsSlice.reducer;
